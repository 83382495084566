import { NumberDecrementStepper, NumberIncrementStepper, NumberInput as NumberInputChakra, NumberInputField, NumberInputProps, NumberInputStepper } from '@chakra-ui/react';

type Props = {
  placeholder?: string;
}

export function NumberInput({ placeholder, ...inheritedProps }: Props & NumberInputProps) {
  return (
    <NumberInputChakra {...inheritedProps}>
      <NumberInputField placeholder={placeholder} bg="white" />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInputChakra>
  );
}