import { UserDetails } from '../entities/User';
import { formatPrice } from '../utils/prices';

interface Props {
  value: number;
  currency: string;
  targetCurrency: UserDetails['currency'];
  fractionDigits?: number;
}

export function PriceInCurrency({ value, currency, targetCurrency, fractionDigits }: Props) {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: fractionDigits ?? 2,
    maximumFractionDigits: fractionDigits ?? 2
  };

  if (targetCurrency.id === currency) {
    return formatPrice(value, currency, options);
  }

  try {
    const { rate } = targetCurrency.targetExchangeRates.find(({ sourceId }) => sourceId === currency);

    return formatPrice(value * rate, targetCurrency.id, options);
  } catch (err) {
    return formatPrice(value, currency, options);
  }
}