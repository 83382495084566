import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const line = definePartsStyle({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  tab: {
    _active: {
      background: 'transparent'
    },
    _selected: {
      borderColor: 'black',
      color: 'black'
    },
    borderBottom: '1px solid',
    color: 'gray.400',
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '-1px',
    padding: '0 0 10px 0'
  },
  tablist: {
    bg: 'white',
    borderBottom: '1px solid',
    gap: '40px',
    px: 8
  },
  tabpanel: {
    p: 0,
    w: '100%'
  },
  tabpanels: {
    alignSelf: 'center',
    maxW: '1184px',
    p: 8
  }
});

export const Tabs = defineMultiStyleConfig({
  variants: { line }
});