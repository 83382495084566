import { HStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';

interface Props {
  rating: number;
}

export function RatingStars(props: Props) {
  return (
    <HStack alignItems="flex-end" spacing={1} ml="-2px">
      {...[1, 2, 3, 4, 5].map((value) => <Icon key={value} size={5} icon="star" color={value > Math.round(props.rating) ? 'gray.200' : 'orange.300'} />)}
    </HStack>
  );
}