import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  background: 'white',
  fontSize: '14px'
});

const sm = defineStyle({
  borderRadius: 4,
});

const md = defineStyle({
  borderRadius: 8,
});

export const Textarea = defineStyleConfig({
  variants: { outline },
  sizes: { sm, md }
});