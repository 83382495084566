import sanitizeHtml from 'sanitize-html';

export function isValidEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isEmpty(value: string | null) {
  if (typeof value !== 'string') {
    return true;
  }

  return value.trim().length === 0;
}

export function shortenString(string: string, limit: number) {
  return string.length >= limit ? string.substring(0, limit).concat('…') : string;
}

export function getTmpId() {
  return `tmp-${crypto.randomUUID()}`;
}

export function sanitize(string: string) {
  return sanitizeHtml(string, {
    allowedTags:['b', 'i', 'em', 'strong', 'ol', 'ul', 'li', 'br']
  });
}