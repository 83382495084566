import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT_CONTENT_TYPES } from '../../../constants/ContentTypes';
import { PartnerRequestCommentAttachmentJSON } from '../../../entities/PartnerRequestCommentAttachment';
import { FileInput } from '../../FileInput';

interface Props {
  attachments: Pick<PartnerRequestCommentAttachmentJSON, 'id' | 'filename' | 'path' | 'size'>[];
  onDownload(attachmentId: number): void
}

export function Attachments({ attachments, onDownload }: Props) {
  const { t } = useTranslation('common');

  return (
    <VStack w="100%" spacing="10px">
      {
        attachments.map(attachment =>
          <FileInput
            key={attachment.id}
            label={t('common:fields.fileDocument.label')}
            sublabel={t('common:fields.fileDocument.attachmentTypes')}
            contentTypes={ATTACHMENT_CONTENT_TYPES}
            defaultName={attachment.filename}
            defaultSize={attachment.size}
            defaultIdentifier={attachment.id}
            isSavedFile={true}
            onDownload={onDownload}
          />
        )
      }
    </VStack>
  );
}