import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { Trans } from 'react-i18next';

interface Props {
  ratingAverage: number;
  ratingCount: number;
}

export function Subtitle({ ratingAverage, ratingCount }: Props) {
  return (
    <HStack spacing={2}>
      <Icon mt="-1px" icon="star" size={4} color="orange.300" />

      <HStack spacing={1}>
        <Trans
          i18nKey="experience:details.partner.reviews"
          values={{
            rating: ratingAverage.toFixed(1),
            count: ratingCount
          }}
          components={{
            rating: <Text fontSize={14} color="black" fontWeight={700} />,
            count: <Text fontSize={14} color="gray.400" />
          }}
        />
      </HStack>
    </HStack>
  );
}