import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  ButtonProps,
  HStack,
  Text
} from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  buttonLabel: string;
  children: React.ReactElement;
  message: string | React.ReactElement;
  title: string;
  buttonColor?: ButtonProps['colorScheme'];
  onConfirm(): void;
}

export function ConfirmModal(props: Props) {
  const { t } = useTranslation('common');

  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(true);
  }

  function onClose() {
    setIsOpen(false);
  }

  function onConfirm() {
    props.onConfirm();

    setIsOpen(false);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <AlertDialog leastDestructiveRef={null} isOpen={isOpen} onClose={onClose} size="xl">
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            { props.title }
            <AlertDialogCloseButton />
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text p="10px 20px" fontSize={14} borderLeft="4px solid" borderColor="orange.400">
              { props.message }
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="gray" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme={props.buttonColor || 'pink'} size="sm" onClick={onConfirm}>
                { props.buttonLabel }
              </PrimaryButton>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}