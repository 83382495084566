import { DEFAULT_UTC_TIMEZONE } from '../entities/Address';
import PropositionsGroupEntity, { PropositionsGroupJSON } from '../entities/PropositionsGroup';

export function isPropositionsGroupDraft({ status }: Pick<PropositionsGroupEntity, 'status'>): boolean {
  return status === 'DRAFT';
}

export function isPropositionsGroupOpen({ status }: Pick<PropositionsGroupEntity, 'status'>): boolean {
  return status === 'OPEN';
}

export function isPropositionsGroupClosed({ status }: Pick<PropositionsGroupEntity, 'status'>): boolean {
  return status === 'CLOSED';
}

export function hasAPropositionsGroupOpen(propositionsGroups: (PropositionsGroupEntity | PropositionsGroupJSON)[]) {
  return propositionsGroups.some(isPropositionsGroupOpen);
}

export function getPropositionsGroupTimezone(propositionsGroup: PropositionsGroupJSON) {
  return propositionsGroup.address?.timezone || DEFAULT_UTC_TIMEZONE;
}