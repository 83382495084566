import { getPlacesAutoComplete } from '@jurnee/common/src/api/places';
import { logger } from '@jurnee/common/src/browser/Logger';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { PlacePredictionJSON } from '@jurnee/common/src/entities/Place';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(prediction: PlacePredictionJSON): void;
}

export function PlaceSearch({ onChange }: Props) {
  const { t } = useTranslation('account', { keyPrefix: 'googleMaps' });

  const [predictions, setPredictions] = useState<PlacePredictionJSON[]>([]);
  const [input, setInput] = useState('');

  async function search() {
    try {
      const { list } = await getPlacesAutoComplete({ input, isDetailed: true });

      setPredictions(list);
    } catch (err) {
      logger.error(err);
    }
  }

  useEffect(() => {
    if (input.length > 0) {
      const timer = window.setTimeout(search, 1000);

      return () => window.clearInterval(timer);
    }
  }, [input]);

  const options = predictions.map(prediction => ({
    label: prediction.description,
    img: prediction.place.photos.length > 0 ? prediction.place.photos[0].path : null,
    value: prediction
  }));

  return (
    <SearchSelect<PlacePredictionJSON>
      options={options}
      openMenuOnFocus={false}
      maxMenuHeight={300}
      placeholder={t('placeholder')}
      onInputChange={setInput}
      onChange={({ value }) => onChange(value)}
      noOptionsMessage={() => null}
      isDisabled={false}
      filterOption={() => true}
    />
  );
}