import { Text, TextProps } from '@chakra-ui/react';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { PropositionItemJSON } from '@jurnee/common/src/entities/PropositionItem';
import { formatAddress } from '@jurnee/common/src/utils/addresses';
import { useTranslation } from 'react-i18next';
import { getItemAddress } from '../utils/bookingsItems';

interface Props extends TextProps {
  item: BookingItemJSON | PropositionItemJSON;
}

function getFormattedItemAddress(item: BookingItemJSON | PropositionItemJSON) {
  const { t } = useTranslation('common');

  if (item.locationType === 'VIRTUAL') {
    return t('virtual');
  }

  const address = getItemAddress(item);

  return address ? formatAddress(address) : null;
}

export function ItemAddress({ item, ...props }: Props) {
  const address = getFormattedItemAddress(item);

  if (address) {
    return <Text {...props}>{address}</Text>;
  }

  return null;
}