import { api } from '@jurnee/common/src/browser/Api';
import { PlaceAddressDTO, PlaceDTO, PlacesAutocompleteBody } from '@jurnee/common/src/dtos/places';
import { List } from '@jurnee/common/src/serializers';

export function getPlacesAutoComplete(payload: PlacesAutocompleteBody): Promise<List<PlaceDTO, never>> {
  return api.post(`places/autocomplete`, payload);
}

export function getPlaceAddress(placeId: string): Promise <PlaceAddressDTO> {
  return api.get(`places/${placeId}/address`);
}