import { HStack, Input, Progress, Text, TextProps, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ContentType, IMAGE_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { resizeImage } from '@jurnee/common/src/utils/images';
import { useRef, useState } from 'react';

interface Props {
  label: string;
  sublabel: string;
  contentTypes: ContentType[];
  defaultName?: string;
  defaultSize?: number;
  defaultIdentifier?: number;
  imageMaxWidth?: number;
  labelMaxWidth?: number;
  isSavedFile?: boolean;
  isLoading?: boolean;
  onChange?(file: File): void;
  onDownload?(identifier: number): void;
  onRemove?(identifier: number): void;
}

interface FileProps {
  name: string;
  size: number;
  maxWidth: TextProps['maxWidth'];
  isSavedFile: boolean;
}

function Label({ label, name, maxWidth, isSavedFile }: Pick<FileProps, 'name' | 'maxWidth' | 'isSavedFile'> & Pick<Props, 'label'>) {
  if (!name) {
    return <Text lineHeight="16px" noOfLines={1}>{label}</Text>;
  }

  return (
    <HStack spacing={1}>
      <Text maxW={maxWidth} lineHeight="16px" noOfLines={1}>{name}</Text>
      { isSavedFile && <Icon icon="circleCheckLine" color="teal.400" size={4} /> }
    </HStack>
  );
}

function formatFileSize(bytes: number) {
  const k = 1000;
  const units = ['B', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const decimals = i > 1 ? 2 : 0;

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${units[i]}`;
}

function SubLabel({ sublabel, size }: Pick<FileProps, 'size'> & Pick<Props, 'sublabel'>) {
  return (
    <Text fontSize={12} lineHeight="14px" color="gray.400">
      {size ? formatFileSize(size) : sublabel}
    </Text>
  );
}

export function FileInput(props: Props) {
  const fileInput = useRef(null);

  const [name, setName] = useState(props.defaultName);
  const [size, setSize] = useState(props.defaultSize);
  const [identifier, setIdentifier] = useState(props.defaultIdentifier);
  const [dragActive, setDragActive] = useState(false);

  const hasFile = !!name && !!size;

  function handleClick() {
    fileInput.current.click();
  }

  function handleFileChange(file: File) {
    setName(file?.name);
    setSize(file?.size);
    setIdentifier(file?.lastModified);
    setDragActive(false);
    props.onChange(file);
  }

  async function handleFile(file: File) {
    if (!file) {
      return null;
    }

    if (props.imageMaxWidth && IMAGE_CONTENT_TYPES.includes(file.type as ContentType)) {
      const resizedImage = await resizeImage(file, props.imageMaxWidth);
      return handleFileChange(resizedImage);
    }

    return handleFileChange(file);
  }

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    e.stopPropagation();
    e.preventDefault();

    const [file] = e.target.files;

    handleFile(file);
  }

  async function handleDrop(e: React.DragEvent<HTMLDivElement>): Promise<void> {
    e.stopPropagation();
    e.preventDefault();

    const [file] = e.dataTransfer.files;

    handleFile(file);
  }

  function handleDrag(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (['dragenter', 'dragover'].includes(e.type)) {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  function handleRemove(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    const currentIdentifier = identifier;

    setName(null);
    setSize(null);
    setIdentifier(null);
    setDragActive(false);

    props.onRemove(currentIdentifier);
  }

  const editProps = props.onChange ? {
    borderColor: dragActive ? 'gray.300' : 'gray.200',
    _hover: { borderColor:'gray.300', cursor: 'pointer' },
    _active: { borderColor:'gray.400', cursor: 'pointer' }
  } : {};

  return (
    <VStack
      w="100%"
      bgColor="white"
      border={props.isSavedFile ? '1px solid' : '1px dashed'}
      borderColor="gray.200"
      borderRadius={4}
      spacing={0}
      overflow="hidden"
      {...editProps}
    >
      <HStack
        w="100%"
        p={3}
        spacing={2}
        onClick={handleClick}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <HStack w="100%" justifyContent="space-between">
          <VStack spacing={1} alignItems="flex-start">
            <Label maxWidth={props.labelMaxWidth || 260} label={props.label} name={name} isSavedFile={props.isSavedFile} />
            <SubLabel sublabel={props.sublabel} size={size} />
          </VStack>

          {
            hasFile ? (
              <HStack spacing={2}>
                {
                  props.onDownload &&
                  <Icon
                    icon="download"
                    size={5}
                    _hover={{ color: 'gray.600', cursor: 'pointer' }}
                    onClick={() => props.onDownload(identifier)}
                  />
                }
                {
                  props.onRemove &&
                  <Icon
                    icon="squareRoundedX"
                    size={5}
                    _hover={{ color: 'gray.600', cursor: 'pointer' }}
                    onClick={handleRemove}
                  />
                }
              </HStack>
            ) : (
              <Icon icon="fileUpload" size={5} />
            )
          }
        </HStack>

        <Input
          key={name}
          type="file"
          accept={props.contentTypes.join(', ')}
          onChange={handleChange}
          ref={fileInput}
          isDisabled={!props.onChange}
          hidden
        />
      </HStack>

      {
        props.isLoading &&
          <Progress
            w="100%"
            mt="-4px"
            size="xs"
            colorScheme="gray"
            isIndeterminate
          />
      }
    </VStack>
  );
}