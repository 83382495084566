import { Heading, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../entities/Experience';

interface Props {
  experience: ExperienceJSON;
}

export function ImportantInfo({ experience }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.importantInfo' });

  return (
    <VStack w="50%" spacing={5} alignItems="flex-start">
      <Heading>{t('title')}</Heading>

      <UnorderedList spacing={4}>
        {
          experience.importantInformation.map((item, index) => (
            <ListItem key={index} fontSize={14}>{item}</ListItem>
          ))
        }

        <ListItem fontSize={14}>{t(experience.wheelchairAccessible ? 'wheelchairAccessible' : 'notWheelchairAccessible')}</ListItem>

        {
          experience.pregnantAccessible &&
            <ListItem fontSize={14}>{t('notPregnantAccessible')}</ListItem>
        }
      </UnorderedList>
    </VStack>
  );
}