import { UseToastOptions } from '@chakra-ui/react';

const toastOptions: UseToastOptions = {
  duration: 2500,
  isClosable: true,
  position: 'bottom-right',
  variant: 'left-accent'
};

export function getSuccessToast(title: string, description?: string): UseToastOptions {
  return {
    ...toastOptions,
    title,
    description,
    status: 'success'
  };
}

export function getErrorToast(title: string, description?: string): UseToastOptions {
  return {
    ...toastOptions,
    title,
    description,
    status: 'error'
  };
}