import { Box } from '@chakra-ui/react';
import { ImageJSON } from '../../../../entities/Image';
import { getCdnImageUrl } from '../../../../utils/core';

interface Props {
  image?: ImageJSON;
}

export function PartnerPhoto({ image }: Props) {
  const imageProps = image ? { bgImage: `url('${getCdnImageUrl(image.path)}');` } : null;

  return (
    <Box
      minW="60px"
      minH="60px"
      bgColor="rgb(30, 0, 45)"
      bgPosition="center"
      bgSize="cover"
      alignSelf="stretch"
      borderRadius="4px"
      {...imageProps}
    />
  );
}