import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const outline = definePartsStyle({
  container: {
    w: '100%',
    bgColor: 'white',
    borderColor: 'gray.200',
    borderRadius: 4,
    p: 4
  }
});

export const Card = defineMultiStyleConfig({
  defaultProps: {
    variant: 'outline'
  },
  variants: { outline }
});