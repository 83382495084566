export type DashboardJwt = {
  userId: number;
  companyId: number;
};

export type PartnerJwt = {
  partnerUserId: string;
  partnerId: number;
}

export function parseJwt<T extends DashboardJwt | PartnerJwt>(token: string): T {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}