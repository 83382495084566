import { BookingDetails } from '@jurnee/common/src/entities/Booking';
import { User, UserDetails, UserJSON, UserUpdate } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from './arrays';
import { isEmpty } from './strings';

export function trimFullName(user: Pick<User, 'firstName' | 'lastName'>) {
  const names = [];

  if (user.firstName && !isEmpty(user.firstName)) {
    names.push(user.firstName.trim());
  }

  if (user.lastName && !isEmpty(user.lastName)) {
    names.push(user.lastName.trim());
  }

  return names.join(' ');
}

export function getUserLabel(user: Pick<User, 'firstName' | 'lastName' | 'email'>) {
  if (!user) {
    return 'Unknown';
  }

  if (user.firstName) {
    return trimFullName(user);
  }

  return user.email;
}

export function sortByUserLabel<T extends Pick<UserJSON, 'firstName' | 'lastName' | 'email'>>(users: T[]) {
  return sortAlphabeticallyBy(users.map(user => ({ ...user, label: getUserLabel(user) })), 'label');
}

export function isAdmin(user: UserDetails) {
  return Array.isArray(user.usersRoles) && user.usersRoles.some(({ role }) => role.key === 'ADMIN');
}

export function isOrganizer(user: UserDetails) {
  return Array.isArray(user.usersRoles) && user.usersRoles.some(({ role }) => role.key === 'ORGANIZER');
}

export function getUserRole(user: UserDetails) {
  if (isAdmin(user)) {
    const userRole = user.usersRoles.find(({ role }) => role.key === 'ADMIN');
    return userRole?.role;
  }

  if (isOrganizer(user)) {
    const userRole = user.usersRoles.find(({ role }) => role.key === 'ORGANIZER');
    return userRole?.role;
  }

  return null;
}

export function getDomainFromEmail(email: string) {
  return email.split('@').pop();
}

export function getInviteLink(baseUrl: string, token: string, email: UserDetails['email'], bookingId?: BookingDetails['id']) {
  const link = new URL(`${baseUrl}/signup`);

  link.searchParams.set('email', email);
  link.searchParams.set('token', token);

  if (bookingId) {
    link.searchParams.set('redirect', `/bookings/${bookingId}`);
  }

  return link.toString();
}

export function cleanData<T extends UserUpdate>(data: T): T {
  if (typeof data.email === 'string') {
    data.email = data.email.trim().toLowerCase();
  }

  if (typeof data.firstName === 'string') {
    data.firstName = data.firstName.trim();
  }

  if (typeof data.lastName === 'string') {
    data.lastName = data.lastName.trim();
  }

  return data;
}