export const colors = {
  blue: {
    10: '#FAFAFF',
    50: '#E9E9FC',
    100: '#C0C0F6',
    200: '#9898F1',
    300: '#7070EB',
    400: '#4747E6',
    500: '#1F1FE0',
    600: '#1919B3',
    700: '#131386',
    800: '#0C0C5A',
    900: '#06062D'
  },
  yellow: {
    50: '#FFF8E5',
    100: '#FFEBB8',
    200: '#FFDE8A',
    300: '#FFD15C',
    400: '#FFC42E',
    500: '#FFB800',
    600: '#CC9300',
    700: '#996E00',
    800: '#664900',
    900: '#332500'
  },
  pink: {
    10: '#FFF5FA',
    50: '#FBE9F3',
    100: '#F5C1DC',
    200: '#EF9AC5',
    300: '#E972AF',
    400: '#E24B98',
    500: '#DC2382',
    600: '#B01C68',
    700: '#84154E',
    800: '#580E34',
    900: '#2C071A'
  },
  orange: {
    50: '#FCF1E8',
    100: '#F7D8BF',
    200: '#F2BF96',
    300: '#EDA66E',
    400: '#E88D45',
    500: '#E3741C',
    600: '#B65D16',
    700: '#884611',
    800: '#5B2F0B',
    900: '#2D1706'
  },
  red: {
    10: '#FFF5F5',
    50: '#FBEAEA',
    100: '#F3C4C4',
    200: '#EB9D9D',
    300: '#E47777',
    400: '#DC5151',
    500: '#D42B2B',
    600: '#AA2222',
    700: '#7F1A1A',
    800: '#551111',
    900: '#2B0909'
  },
  gray: {
    10: '#FAFCFE',
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923'
  }
};