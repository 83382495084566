import { Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function CancellationPolicy() {
  const { t } = useTranslation('experience', { keyPrefix: 'details.cancellationPolicy' });

  return (
    <VStack w="50%" spacing={5} alignItems="flex-start">
      <Heading>{t('title')}</Heading>
      <Text>{t('content')}</Text>
    </VStack>
  );
}