import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const sm = defineStyle({
  borderRadius: 4,
  fontWeight: 500
});

const md = defineStyle({
  borderRadius: 8
});

const xs = defineStyle({
  borderRadius: 4,
  fontSize: 14,
  fontWeight: 500,
  height: '24px',
  paddingX: 2
});

export const Button = defineStyleConfig({
  sizes: {
    sm,
    md,
    xs
  },
  defaultProps: {
    size: 'md'
  }
});