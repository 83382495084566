import { listAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(listAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    marginInlineStart: '0.5em',
    marginLeft: '0.5em'
  }
});

export const List = defineMultiStyleConfig({
  baseStyle
});