import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const Heading = defineStyleConfig({
  sizes: {
    xs: defineStyle({
      fontSize: '14px',
      lineHeight: '150%'
    }),
    sm: defineStyle({
      fontSize: '16px',
      lineHeight: '140%'
    }),
    md: defineStyle({
      fontSize: '20px',
      lineHeight: '130%'
    }),
    lg: defineStyle({
      fontSize: '24px',
      lineHeight: '130%'
    }),
    xl: defineStyle({
      fontSize: '36px',
      lineHeight: '120%'
    }),
    xxl: defineStyle({
      fontSize: '48px',
      lineHeight: '110%'
    }),
    xxxl: defineStyle({
      fontSize: '64px',
      lineHeight: '110%'
    })
  },
  defaultProps: {
    size: 'lg'
  }
});