import { Avatar, AvatarProps } from '@chakra-ui/react';

interface Props extends AvatarProps {
  user: { firstName: string, lastName: string, imageUrl?: string | null };
}

export default function(props: Props) {
  return (
    <Avatar
      bg="gray.100"
      color="black"
      size="sm"
      borderRadius={4}
      fontWeight="600"
      name={`${props.user?.firstName || '?'} ${props.user?.lastName || '?'}`}
      src={props.user?.imageUrl}
      {...props}
    />
  );
}