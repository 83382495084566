import { addresses, Prisma } from '@prisma/client';
import { Exclude, Expose } from 'class-transformer';

export const DEFAULT_UTC_TIMEZONE = 'Etc/UTC';

export type Address = addresses;

export type AddressCreate = Prisma.addressesCreateInput;

export type AddressUpdate = Prisma.addressesUncheckedUpdateInput;

export type AddressJSON = AddressEntity;

@Exclude()
export default class AddressEntity {
  @Expose() id: number;
  @Expose() additionalInformation: string | null;
  @Expose() address: string;
  @Expose() city: string;
  @Expose() country: string;
  @Expose() countryCode: string;
  @Expose() description: string | null;
  @Expose() lat: number | null;
  @Expose() long: number | null;
  @Expose() postalCode: string;
  @Expose() state: string | null;
  @Expose() timezone: string;
}