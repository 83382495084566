import Resizer from 'react-image-file-resizer';
import { ImageJSON } from '../entities/Image';
import { getCdnImageUrl } from './core';

export function resizeImage(image: File, maxWidth: number): Promise<File> {
  const quality = 90;
  const rotation = 0;
  const outputType = 'file';

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      maxWidth,
      maxWidth,
      'jpeg',
      quality,
      rotation,
      (file) => resolve(file as File),
      outputType
    );
  });
}

function preloadImage({ path }: ImageJSON) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;
    image.src = getCdnImageUrl(path);
  });
}

export function preloadImages(images: ImageJSON[]) {
  return Promise.allSettled(images.map(image => preloadImage(image)));
}