import { HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ExperienceJSON, ExperienceRatingJSON } from '../../../entities/Experience';
import { ExperienceAverageRating } from '../../experiences/ExperienceAverageRating';
import { ExperienceRecommendedLabel } from '../../experiences/ExperienceRecommendedLabel';
import { ImagesGrid } from './ImagesGrid';

interface Props {
  experience: ExperienceJSON;
  rating: ExperienceRatingJSON;
  isCompanyRecommended?: boolean;
  children?: ReactNode;
}

export function ExperienceHeader(props: Props) {
  const images = props.experience.experiencesImages.map(({ image }) => image);

  return (
    <VStack w="100%" spacing={8}>
      <VStack w="100%" spacing={2}>
        <HStack w="100%" justifyContent="space-between" h={7}>
          <Heading size="xl">{props.experience.name}</Heading>

          <HStack spacing={2}>
            { props.children }
          </HStack>
        </HStack>

        <HStack w="100%" spacing={1}>
          <ExperienceAverageRating rating={props.rating} />
          {
            props.isCompanyRecommended && (
              <>
                <Text key="dot" fontSize="14px" fontWeight={500} lineHeight="16px">·</Text>
                <ExperienceRecommendedLabel />
              </>
            )
          }
        </HStack>
      </VStack>

      <ImagesGrid images={images} />
    </VStack>
  );
}