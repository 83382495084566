import { Box, HStack, Text } from '@chakra-ui/react';

interface Props {
  color: string;
  label: string | React.ReactNode;
}

function getColorProps(color: string) {
  switch (color) {
  case 'teal':
    return {
      borderColor: 'teal.100',
      bgColor: 'teal.50',
      color: 'teal.400'
    };
  case 'black':
    return {
      borderColor: 'gray.200',
      bgColor: 'gray.50',
      color: 'black'
    };
  case 'pink':
    return {
      borderColor: 'pink.50',
      bgColor: 'pink.10',
      color: 'pink.400'
    };
  case 'blue':
    return {
      borderColor: 'blue.50',
      bgColor: 'blue.10',
      color: 'blue.400'
    };
  case 'red':
    return {
      borderColor: 'red.50',
      bgColor: 'red.10',
      color: 'red.500'
    };
  case 'orange':
    return {
      borderColor: 'orange.50',
      bgColor: 'orange.10',
      color: 'orange.400'
    };
  default:
    return {
      borderColor: 'gray.100',
      bgColor: 'gray.50',
      color: 'gray.400'
    };
  }
}

export function StatusBadge(props: Props) {
  const { borderColor, bgColor, color } = getColorProps(props.color);

  return (
    <HStack display="inline-flex" spacing={1} px="6px" py="3px" color={color} bgColor={bgColor} border="1px solid" borderRadius="10px" borderColor={borderColor}>
      <Box bgColor={color} w="4px" h="4px" borderRadius="50%" />

      <Text fontSize={10} fontWeight={500} lineHeight="8px" textTransform="uppercase" noOfLines={1}>
        { props.label }
      </Text>
    </HStack>
  );
}