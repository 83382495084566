import { HStack, Spacer, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import * as React from 'react';
import { UserDetails } from '../../../../entities/User';
import { PrimaryButton } from '../../../buttons/PrimaryButton';
import { PriceLine } from './PriceLine';

interface Props {
  product: ProductJSON;
  participants: number;
  children: React.ReactNode;
  currency: Currency;
  targetCurrency: UserDetails['currency'];
  onSubmit(): void;
  price: number;
  submitDisabled: boolean;
  isSubmitting?: boolean;
  submitLabel: string;
}

export function Step(props: Props) {
  const hasPriceLine = props.product && props.participants;

  return (
    <VStack w="100%" spacing={5} mt={5}>
      <VStack w="100%" p={0} spacing={4}>
        { props.children }
      </VStack>

      <HStack w="100%" p={0}>
        {
          hasPriceLine &&
            <PriceLine
              product={props.product}
              participants={props.participants}
              price={props.price}
              currency={props.currency}
              targetCurrency={props.targetCurrency}
            />
        }
        <Spacer/>
        <PrimaryButton isDisabled={props.submitDisabled || !!props.isSubmitting} onClick={props.onSubmit} isLoading={!!props.isSubmitting}>{props.submitLabel}</PrimaryButton>
      </HStack>
    </VStack>
  );
}