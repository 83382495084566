import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  field: {
    background: 'white',
    fontSize: '14px'
  }
});

const sm = definePartsStyle({
  field: {
    borderRadius: 4,
  }
});

const md = definePartsStyle({
  field: {
    borderRadius: 8,
  }
});

export const Input = defineMultiStyleConfig({
  sizes: {
    sm,
    md
  },
  variants: { outline }
});