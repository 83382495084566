import { useTranslation } from 'react-i18next';
import { Currency } from '../../../../entities/Currency';
import { ExperienceJSON } from '../../../../entities/Experience';
import { LanguageJSON } from '../../../../entities/Language';
import { UserDetails } from '../../../../entities/User';
import { isBoxShipping, isGeneric, isVirtual } from '../../../../utils/experiences';
import { getMaxThreshold, getProductPrice, isParticipantsRangeValid } from '../../../../utils/products';
import { CitySearchParams } from '../../../AddressSearch';
import PackageRadioGroup from '../../../PackageRadioGroup';
import { TimeSlot } from '../../../TimeSlotSelector';
import { Step } from '../Step';
import { BoxShippingFields } from './BoxShippingFields';
import { ExperienceFields } from './ExperienceFields';

export interface Props {
  experience: ExperienceJSON;
  productId: number;
  participants: number;
  currency: Currency;
  targetCurrency: UserDetails['currency'];
  date: Date;
  languages: LanguageJSON[];
  languageId: number;
  isSubmitDisabled: boolean;
  onParticipantsChange(participants: number): void;
  onDateChange(date: Date): void;
  onTimeChange(slot: TimeSlot): void;
  onLanguageChange(languageId: number): void;
  onProductChange(productId: number): void;
  onCityChange(data: CitySearchParams): void;
  onInfoNext(): void;
}

export const DEFAULT_PARTICIPANTS = 5;

export function InformationForm(props: Props) {
  const { t } = useTranslation('experience');

  const product = props.experience.products.find(({ id }) => id === props.productId);
  const price = product ? getProductPrice(product, props.participants) : null;
  const areParticipantsValid = product ? isParticipantsRangeValid(product, props.participants) : true;
  const maxParticipants = product ? getMaxThreshold(product) : Infinity;

  return (
    <Step
      product={product}
      participants={props.participants}
      submitLabel={t('form.next')}
      price={price}
      currency={props.currency}
      targetCurrency={props.targetCurrency}
      submitDisabled={props.isSubmitDisabled}
      onSubmit={props.onInfoNext}
    >
      {
        isBoxShipping(props.experience) ? (
          <BoxShippingFields
            participants={props.participants}
            maxParticipants={maxParticipants}
            areParticipantsValid={areParticipantsValid}
            date={props.date}
            bookingDeadline={props.experience.bookingDeadline}
            onParticipantsChange={props.onParticipantsChange}
            onDateChange={props.onDateChange}
          />
        ) : (
          <ExperienceFields
            participants={props.participants}
            maxParticipants={maxParticipants}
            areParticipantsValid={areParticipantsValid}
            date={props.date}
            minDuration={props.experience.minDuration}
            languages={props.languages}
            languageId={props.languageId}
            isGeneric={isGeneric(props.experience)}
            isVirtual={isVirtual(props.experience)}
            bookingDeadline={props.experience.bookingDeadline}
            onParticipantsChange={props.onParticipantsChange}
            onDateChange={props.onDateChange}
            onTimeChange={props.onTimeChange}
            onLanguageChange={props.onLanguageChange}
            onCityChange={props.onCityChange}
          />
        )
      }

      {
        props.experience.products.length > 0 &&
          <PackageRadioGroup
            currentProductId={props.productId}
            products={props.experience.products}
            quantity={props.participants}
            targetCurrency={props.targetCurrency}
            onChange={props.onProductChange}
          />
      }
    </Step>
  );
}