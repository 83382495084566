import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    position: 'auto',
  },
  excessLabel: {
    bgColor: 'gray.100',
    border: '2px solid',
    borderColor: 'white',
    color: 'black',
    fontSize: '12.8px',
    fontWeight: '600'
  }
});

const xs = definePartsStyle({
  container: {
    position: 'auto',
    width: '22px',
    height: '22px'
  },
  excessLabel: {
    fontSize: 'calc(1.5rem / 2.5)'
  }
});

const bgWhite = definePartsStyle({
  excessLabel: {
    bgColor: 'white',
    color: 'black',
    fontSize: '12.8px',
    fontWeight: '600',
  },
});

export const Avatar = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    xs
  },
  variants: {
    bgWhite
  }
});