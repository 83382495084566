import { datadogLogs, StatusType } from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';

interface DashboardContext {
  userId: number;
  companyId: number;
}

interface PartnerContext {
  userId: string;
  partnerId: number;
}

type LoggerContext = DashboardContext | PartnerContext;

export class Logger {

  private logger = datadogLogs.logger;

  constructor() {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        release: process.env.VERSION,
        environment: process.env.APP_ENV
      });
    }

    datadogLogs.init({
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: process.env.APP_NAME,
      site: 'datadoghq.eu',
      version: process.env.VERSION
    });

    datadogLogs.logger.setContext({ version: process.env.VERSION });
    datadogLogs.logger.setLevel(StatusType.info);
    datadogLogs.logger.setHandler(process.env.APP_ENV === 'production' ? 'http' : 'console');
  }

  public setContext(context: LoggerContext) {
    this.logger.setContext({ version: process.env.VERSION, ...context});
    Sentry.setUser({ id: context.userId.toString() });
  }

  private log(message: string, level: StatusType, data: object) {
    this.logger.log(message, { ...data, level }, level);
    Sentry.addBreadcrumb({ message, data });
  }

  public info(message: string, data: object = {}) {
    this.log(message, StatusType.info, data);
  }

  public warn(message: string, data: object = {}) {
    this.log(message, StatusType.warn, data);
  }

  public error(message: string, data: object = {}) {
    this.log(message, StatusType.error, data);
  }

  public report(message: string, data: object = {}) {
    Sentry.captureException(message, data);
  }

}

export const logger = new Logger();