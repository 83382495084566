import { Image } from '@chakra-ui/react';

interface Props {
  zoom: number;
  width: number;
  height: number;
  overlay: string;
  long: number;
  lat: number;
}

const BASE_URL = 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/static';

export function StaticMap(props: Props) {
  const overlay = `${props.overlay}(${props.long},${props.lat})`;
  const imageUrl = `${BASE_URL}/${overlay}/${props.long},${props.lat},${props.zoom},0,0/${props.width}x${props.height}@2x?access_token=${process.env.MAPBOX_ACCESS_TOKEN}`;

  return <Image
    borderRadius={8}
    border="1px solid"
    borderColor="blue.50"
    overflow="hidden"
    src={imageUrl}
  />;
}