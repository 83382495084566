import { api } from '@jurnee/common/src/browser/Api';
import { PartnerLoginResponse } from '@jurnee/common/src/dtos/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { completeMagicLink } from 'api/auth';
import { RootState } from '..';

export const login = createAsyncThunk<PartnerLoginResponse, string, { state: RootState }>('AUTH_LOGIN', async (token, thunkAPI) => {
  try {
    const data = await completeMagicLink(token);

    localStorage.setItem('token', data.token);

    api.setToken(data.token);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const connectAs = createAsyncThunk<string, string, { state: RootState }>('AUTH_CONNECT_AS', async (token, thunkAPI) => {
  try {
    localStorage.setItem('token', token);

    api.setToken(token);

    return token;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});