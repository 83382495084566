import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const link = defineStyle({
  _hover: {
    textDecoration: 'underline'
  },
  color: 'blue.400',
  cursor: 'pointer'
});

const label = defineStyle({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '16px'
});

const sublabel = defineStyle({
  color: 'gray.400',
  fontSize: '14px',
  lineHeight: '14px'
});

export const Text = defineStyleConfig({
  variants: {
    label,
    link,
    sublabel
  }
});