import { Button, ButtonProps, LinkProps } from '@chakra-ui/react';
import * as React from 'react';

export class SecondaryButton extends React.PureComponent<ButtonProps & LinkProps> {

  get buttonProps() {
    const color = this.props.colorScheme ?? 'blue';

    if (color === 'gray') {
      return {
        bg: `gray.100`,
        color: 'black',
        _hover: {
          background: `gray.200`
        },
        _active: {
          background: `gray.300`,
        }
      };
    }

    if (color === 'white') {
      return {
        bg: 'white',
        color: 'black',
        _hover: {
          background: 'gray.100'
        },
        _active: {
          background: 'gray.200',
        }
      };
    }

    return {
      bg: `${color}.50`,
      color: `${color}.400`,
      _hover: {
        background: `${color}.100`
      },
      _active: {
        background: `${color}.200`,
        color: `${color}.600`
      }
    };
  }

  render() {
    return (
      <Button {...this.buttonProps} {...this.props}>
        { this.props.children }
      </Button>
    );
  }

}