import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  control: {
    borderColor: 'black',
    borderRadius: 4,
  }
});

const sm = defineStyle({
  control: {
    width: '14px',
    height: '14px'
  }
});

export const Checkbox = defineStyleConfig({
  baseStyle,
  sizes: {
    sm
  },
  defaultProps: {
    size: 'sm'
  }
});