import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    borderColor: 'blue.50',
    boxShadow: 'lg',
    borderRadius: 4,
    p: 1
  },
  item: {
    _hover: {
      bg: 'gray.10'
    },
    _focus:{
      bg: 'gray.10'
    },
    borderRadius: 1,
    lineHeight: 6
  }
});

export const Menu = defineMultiStyleConfig({
  baseStyle
});