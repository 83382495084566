import { DEFAULT_UTC_TIMEZONE } from '../entities/Address';
import BookingItemEntity, { BookingItemJSON } from '../entities/BookingItem';
import BookingItemTypeEntity, { BookingItemTypeJSON } from '../entities/BookingItemType';
import ExperienceEntity from '../entities/Experience';
import { PLACEHOLDER_PATH } from './bookings';

export function hasProcessingStatus({ status }: Pick<BookingItemEntity, 'status'>) {
  return status === 'PROCESSING';
}

export function hasInitialStatus({ status }: Pick<BookingItemEntity, 'status'>) {
  return status === 'INITIAL';
}

export function hasConfirmedStatus({ status }: Pick<BookingItemEntity, 'status'>) {
  return status === 'CONFIRMED';
}

export function hasFinishedStatus({ status }: Pick<BookingItemEntity, 'status'>) {
  return status === 'FINISHED';
}

export function hasPartner({ partnerId }: Pick<BookingItemEntity, 'partnerId'>) {
  return partnerId !== null;
}

export function hasInvalidAmount({ unitAmount }: Pick<BookingItemEntity, 'unitAmount'>) {
  return unitAmount <= 0;
}

export function hasExperienceType({ bookingItemType }: { bookingItemType: BookingItemTypeEntity }) {
  return bookingItemType.key === 'EXPERIENCE';
}

export function hasCustomRequestType({ bookingItemType }: { bookingItemType: BookingItemTypeEntity }) {
  return bookingItemType.key === 'CUSTOM_REQUEST';
}

export function getBookingItemImagePath({ bookingsItemsImages, bookingItemType }: Pick<BookingItemJSON, 'bookingsItemsImages' | 'bookingItemType'>) {
  if (bookingsItemsImages.length > 0) {
    return bookingsItemsImages[0].image.path;
  }

  return getPlaceholderImagePath(bookingItemType.key);
}

export function getPlaceholderImagePath(key: BookingItemTypeJSON['key']) {
  if (!key) {
    return PLACEHOLDER_PATH;
  }

  if (['EXPERIENCE', 'CUSTOM_REQUEST'].includes(key)) {
    return PLACEHOLDER_PATH;
  }

  return `images/placeholder_${key.toLowerCase()}.jpg`;
}

export function getBookingItemTotalPrice({ unitAmount, quantity }: Pick<BookingItemEntity, 'unitAmount' | 'quantity'>) {
  return unitAmount * quantity;
}

export function getBookingItemLocationType({ type }: Pick<ExperienceEntity, 'type'>): BookingItemEntity['locationType'] {
  switch(type) {
  case 'VIRTUAL':
  case 'BOX_SHIPPING':
    return 'VIRTUAL';
  case 'IN_OFFICE':
    return 'IN_OFFICE';
  default:
    return 'PARTNER';
  }
}

export function getInvoiceItemDescription(description: string, participants: number) {
  return participants > 1 ? `${description} (${participants} participants)` : description;
}

export function getBookingItemAddress(bookingItem: BookingItemEntity) {
  if (bookingItem.address) {
    return `${bookingItem.address.city}, ${bookingItem.address.country}`;
  }

  if (bookingItem.partner?.address) {
    return `${bookingItem.partner.address.city}, ${bookingItem.partner.address.country}`;
  }

  return 'Unknown';
}

export function getItemAddress({ locationType, address, partner }: Pick<BookingItemJSON, 'locationType' | 'address' | 'partner'>) {
  if (locationType === 'PARTNER' && partner?.address) {
    return partner.address;
  }

  return address;
}

export function getItemTimezone(item: Pick<BookingItemJSON, 'locationType' | 'address' | 'partner'>) {
  const address = getItemAddress(item);

  return address ? address.timezone : DEFAULT_UTC_TIMEZONE;
}