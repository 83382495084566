import { Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from '../../../../utils/strings';
import { PartnerPhoto } from './PartnerPhoto';
import { Subtitle } from './Subtitle';

interface Props {
  experience: ExperienceJSON;
}

export function PartnerInfo({ experience }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.partner' });

  const { ratingAverage, ratingCount } = useMemo(
    () => experience.partner.partnersProvidersInformation.reduce((totals, { ratingAverage, ratingCount }) => {
      const ratingCountTotal = totals.ratingCount + ratingCount;
      const ratingAverageTotal = ((totals.ratingAverage * totals.ratingCount) + (ratingAverage * ratingCount)) / ratingCountTotal;

      return { ratingAverage: ratingAverageTotal, ratingCount: ratingCountTotal };
    }, { ratingAverage: 0, ratingCount: 0 }),
    [experience]
  );

  return (
    <>
      <Divider />

      <VStack w="100%" spacing={5} alignItems="flex-start">
        <HStack spacing={3} alignItems="flex-start">
          <PartnerPhoto image={experience.experiencesImages[0]?.image} />

          <VStack spacing={1} alignItems="flex-start">
            <Heading size="md" noOfLines={1} mt={1}>
              { t('hostedBy') } { experience.partner.name }
            </Heading>

            { ratingCount && <Subtitle ratingAverage={ratingAverage} ratingCount={ratingCount} /> }
          </VStack>
        </HStack>

        <Text boxSizing="border-box" dangerouslySetInnerHTML={{ __html: sanitize(experience.partner.description) }} />
      </VStack>
    </>
  );
}