import { VStack } from '@chakra-ui/react';
import { ExperienceJSON } from '../../../entities/Experience';
import { isEmpty } from '../../../utils/strings';
import { Content } from './Content';
import { Included } from './Included';
import { PartnerInfo } from './PartnerInfo';
import { Requirements } from './Requirements';

interface Props {
  experience: ExperienceJSON;
}

export function ExperienceDescription({ experience }: Props) {
  const hasIncluded = experience.included.length > 0;
  const hasRequirements = experience.requirementsList.length > 0;
  const hasPartnerInfo = !isEmpty(experience.partner?.description);

  return (
    <VStack w="100%" spacing={8}>
      <Content experience={experience} />
      { hasIncluded && <Included experience={experience} /> }
      { hasRequirements && <Requirements experience={experience} /> }
      { hasPartnerInfo && <PartnerInfo experience={experience} /> }
    </VStack>
  );
}