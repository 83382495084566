import { Menu, MenuButton, MenuButtonProps, MenuList, PlacementWithLogical } from '@chakra-ui/react';
import { Dots } from './Dots';

interface Props extends MenuButtonProps {
  size: 'sm' | 'lg';
  placement?: PlacementWithLogical;
}

export function DotMenu(props: Props) {
  const w = props.size === 'sm' ? 8 : 10;
  const borderRadius = props.size === 'sm' ? 4 : 8;

  return (
    <Menu placement={props.placement ?? 'bottom'}>
      <MenuButton h={w} minW={w} borderRadius={borderRadius} {...props}>
        <Dots size={props.size} color={props.color} />
      </MenuButton>

      <MenuList>
        { props.children }
      </MenuList>
    </Menu>
  );
}