import { Divider, Heading, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../entities/Experience';
import { ListItemCard } from '../../ListItemCard';

interface Props {
  experience: ExperienceJSON;
}

export function Included({ experience }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.included' });

  return (
    <>
      <Divider />

      <VStack w="100%" spacing={5} alignItems="flex-start">
        <Heading>{t('title')}</Heading>

        <VStack w="100%" spacing="10px">
          {
            experience.included.map((item, i) => (
              <ListItemCard key={i} icon="squareRoundedCheck" colorScheme="teal.400" content={item} />
            ))
          }
        </VStack>
      </VStack>
    </>
  );
}