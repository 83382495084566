import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontSize: '14px',
  fontWeight: 700,
  m: '0 0 4px 0'
});

export const FormLabel = defineStyleConfig({
  baseStyle
});