import { Box, Divider, Heading, VStack } from '@chakra-ui/react';
import { ExperienceJSON, ExperienceRatingJSON, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { PartnerProviderInformationJSON } from '@jurnee/common/src/entities/PartnerProviderInformation';
import { useTranslation } from 'react-i18next';
import { ReviewsDrawer } from '../../drawers/ReviewsDrawer';
import { getReviewAuthor } from '../../utils/reviews';
import { ReviewCard } from '../reviews/ReviewCard';
import { ReviewsSummary } from '../reviews/ReviewsSummary';

interface Props {
  experience: ExperienceJSON;
  rating: ExperienceRatingJSON;
  stats: ExperienceStats;
  partnersProvidersInformation: PartnerProviderInformationJSON[];
}

export function ExperienceReviews(props: Props) {
  const { t } = useTranslation('experience');

  const hasReviewsDrawer = props.stats.reviews.length > 5;

  return (
    <>
      <Divider />

      <VStack w="100%" spacing={5} alignItems="flex-start">
        <Heading size="md">{t('details.reviews.title')}</Heading>
        <Box w="100%" sx={{ columnCount: [2], columnGap: 5 }}>
          <Box mb={5}>
            <ReviewsSummary
              avgRating={props.rating.averageRating}
              totalReviews={props.stats.totalReviews}
              reviewsCountGroupByRating={props.stats.reviewsCountGroupByRating}
              partnersProvidersInformation={props.partnersProvidersInformation}
            />
          </Box>
          {
            props.stats.reviews.slice(0,5).map((review, index) => {
              return (
                <Box key={index} mb={index === 4 ? 0 : 5}>
                  <ReviewCard
                    author={getReviewAuthor(review?.review)}
                    rating={review.rating}
                    comment={review.comment}
                    createdAt={review.createdAt}
                    provider={review?.review?.provider}
                  />
                </Box>
              );
            })
          }
        </Box>
      </VStack>

      { hasReviewsDrawer && <ReviewsDrawer reviews={props.stats.reviews} /> }
    </>
  );
}