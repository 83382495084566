import { createStandaloneToast } from '@chakra-ui/react';
import { Option, SearchIconSelect, SearchSelect } from '@jurnee/common/src/components/Select';
import { PlaceAddressDTO, PlaceDTO } from '@jurnee/common/src/dtos/places';
import theme from '@jurnee/common/src/theme';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InputActionMeta } from 'react-select';
import { getPlaceAddress, getPlacesAutoComplete } from '../api/places';

interface OwnProps {
  types: '(cities)' | 'locality|administrative_area_level_3|route';
  defaultValue?: string;
  placeholder?: string;
  variant?: 'searchBar';
  onChange(data: CitySearchParams): void;
}

interface State {
  placeId: string;
  placeLabel: string;
  places: PlaceDTO[];
}

export interface CitySearchParams {
  placeAddress: PlaceAddressDTO;
  description: string;
}

type Props = OwnProps & WithTranslation;

const { toast } = createStandaloneToast({ theme });

class AddressSearch extends React.PureComponent<Props, State> {

  state: State = {
    placeId: null,
    placeLabel: null,
    places: []
  };

  inputTimout: NodeJS.Timeout = null;

  onInputChange = (input: string, { prevInputValue }: InputActionMeta) => {
    this.setState({ ...this.state, places: [] });

    if (!input || input === prevInputValue) {
      return;
    }

    clearTimeout(this.inputTimout);

    this.inputTimout = setTimeout(async () => {
      try {
        const places = await getPlacesAutoComplete({
          input,
          isDetailed: false,
          types: this.props.types
        });

        this.setState({ ...this.state, places: places.list });
      } catch(err) {
        const title = this.props.t('error');

        toast({
          title,
          position: 'bottom-right',
          variant: 'left-accent',
          status: 'error',
          isClosable: true
        });
      }
    }, 500);
  };

  onChange = async (data: { label: string, value: string }) => {
    try {
      this.setState({ ...this.state, placeLabel: data.label, placeId: data.value });
      const placeAddress = await getPlaceAddress(data.value);
      this.setState({ ...this.state, places: [] });
      this.props.onChange({ placeAddress, description: data.label });
    } catch(err) {
      const title = this.props.t('error');
      toast({
        title,
        position: 'bottom-right',
        variant: 'left-accent',
        status: 'error',
        isClosable: true
      });
    }
  };

  get options() {
    return this.state.places.map(({ id, description }) => ({ label: description, value: id }));
  }

  get currentOption() {
    if (this.state.placeLabel && this.state.placeId) {
      return { label: this.state.placeLabel, value: this.state.placeId };
    }

    if (this.props.defaultValue) {
      return { label: this.props.defaultValue, value: this.props.defaultValue };
    }

    return null;
  }

  get selectProps() {
    return {
      options: this.options,
      value: this.currentOption,
      openMenuOnFocus: false,
      maxMenuHeight: 200,
      placeholder: this.props.placeholder || this.props.t('placeholder'),
      onInputChange: this.onInputChange,
      onChange: this.onChange,
      noOptionsMessage: () => null as null,
      filterOption: () => true,
      openMenuOnClick: true
    };
  }
  render() {
    return this.props.variant === 'searchBar' ?
      <SearchIconSelect<PlaceDTO['id']> {...this.selectProps} icon="pin" /> :
      <SearchSelect<PlaceDTO['id']> components={{ Option, IndicatorsContainer: () => null }} {...this.selectProps} />;
  }

}

export default withTranslation('common', { keyPrefix: 'fields.citySearch' })(AddressSearch);