import { Card, Circle, FormControl, HStack, Spacer, Textarea } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT_CONTENT_TYPES } from '../../../constants/ContentTypes';
import { FileInput } from '../../FileInput';
import { AttachmentInput } from './AttachmentInput';

export interface CommentFormData {
  content: string;
  files: File[];
}

interface Props {
  isUploading?: boolean;
  hasFileInput?: boolean;
  onSubmit(data: CommentFormData): Promise<void>;
}

export function CommentForm(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields.comment' });

  const [content, setContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const isDisabled = isEmpty(content) || isSaving;

  const buttonProps = isDisabled ? {
    cursor: 'not-allowed',
    color: 'white',
    bgColor: 'blue.200'
  } : {
    cursor: 'pointer',
    color: 'white',
    bgColor: 'blue.400',
    _hover:{ bgColor: 'blue.600' },
    _active:{ bgColor: 'blue.700' }
  };

  async function onSubmit() {
    if (isDisabled) {
      return;
    }

    setIsSaving(true);

    await props.onSubmit({ content, files });

    setContent('');
    setFiles([]);
    setIsSaving(false);
  }

  function onAddFile(file: File) {
    setFiles([...files, file]);
  }

  function onRemoveFile(identifier: number) {
    setFiles(files.filter(file => file.lastModified !== identifier));
  }

  return (
    <Card display="flex" flexDirection="column" px={4} py={3} gap={3}>
      <FormControl id="comment">
        <Textarea
          size="sm"
          p={0}
          variant="unstyled"
          minHeight="80px"
          placeholder={t('placeholder')}
          resize="none"
          value={content}
          onChange={e => setContent(e.target.value)}
        />
      </FormControl>

      {
        files.map(({ name, size, lastModified }) =>
          <FileInput
            key={lastModified}
            label={t('common:fields.fileDocument.label')}
            sublabel={t('common:fields.fileDocument.attachmentTypes')}
            contentTypes={ATTACHMENT_CONTENT_TYPES}
            defaultName={name}
            defaultSize={size}
            defaultIdentifier={lastModified}
            isLoading={props.isUploading}
            onRemove={onRemoveFile}
          />
        )
      }

      <HStack w="100%" justifyContent="space-between">
        { props.hasFileInput && <AttachmentInput imageMaxWidth={1200} onAddFile={onAddFile} /> }

        <Spacer />

        <Circle minW="22px" minH="22px" onClick={onSubmit} {...buttonProps}>
          <Icon icon="sendPlane" color="white" size={4} />
        </Circle>
      </HStack>
    </Card>
  );
}