import { Input, InputProps } from '@chakra-ui/react';
import ReactDatePicker, { DatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

interface OwnProps {
  inputProps?: InputProps;
}

type Props = OwnProps & DatePickerProps;

export function DatePicker(props: Props) {
  const { inputProps, ...datePickerProps } = props;

  return (
    <ReactDatePicker
      {...datePickerProps}
      calendarStartDay={1}
      customInput={<Input {...inputProps} />}
    />
  );
}