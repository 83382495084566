import { Center, Code, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { useRouteError } from 'react-router-dom';

export function ErrorPage() {
  const error = useRouteError();

  function errorMessage() {
    if (process.env.NODE_ENV !== 'production' && error instanceof Error) {
      return (
        <VStack spacing={5}>
          <Text w="100%"><b>{error.name}:</b> {error.message}</Text>
          <Code whiteSpace="pre-line" p={4}>{error.stack}</Code>
        </VStack>
      );
    }

    return (
      <>
        <Heading>Oops!</Heading>
        <Text w="350px" fontSize={14} textAlign="center" color="gray.400">
          We're not really sure what happened, but something went wrong :(
        </Text>
      </>
    );
  }

  return (
    <Center h="100%" w="600px" margin="auto">
      <VStack w="100%" alignItems="center" spacing={5}>
        <Image src="/assets/illustrations/circle_x.svg" w="150px" h="150px" />

        { errorMessage() }
      </VStack>
    </Center>
  );

}