import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(formAnatomy.keys);

const baseStyle = definePartsStyle({
  helperText: {
    color: 'gray.400',
    m: '0 0 6px 0'
  }
});

export const Form = defineMultiStyleConfig({
  baseStyle
});