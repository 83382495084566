import { HStack, TabPanel, useToast, VStack } from '@chakra-ui/react';
import { getLanguages } from '@jurnee/common/src/api/languages';
import { ExperienceDescription } from '@jurnee/common/src/components/ExperienceDetails/Description';
import { FormTabs } from '@jurnee/common/src/components/ExperienceDetails/Form/FormTabs';
import { DEFAULT_PARTICIPANTS, InfoStep } from '@jurnee/common/src/components/ExperienceDetails/Form/InfoStep';
import { ExperienceHeader } from '@jurnee/common/src/components/ExperienceDetails/Header';
import { ExperienceInfoAndPolicies } from '@jurnee/common/src/components/ExperienceDetails/InfoAndPolicies';
import { ExperienceMap } from '@jurnee/common/src/components/ExperienceDetails/Map';
import { ExperienceReviews } from '@jurnee/common/src/components/ExperienceDetails/Reviews';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ExperienceJSON, ExperienceRatingJSON, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { getDefaultProductId, getExperienceMaxParticipants, hasMap } from '@jurnee/common/src/utils/experiences';
import { getReviewAuthor } from '@jurnee/common/src/utils/reviews';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { getExperienceStats } from 'api/experiences';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPartnerSelector } from 'store/partner/partner.selectors';

interface Props {
  experience: ExperienceJSON;
  rating: ExperienceRatingJSON;
}

export function Preview({ experience, rating }: Props) {
  const toast = useToast();
  const { t } = useTranslation('service');

  const partner = useSelector(getPartnerSelector);

  const [stats, setStats] = useState<ExperienceStats>(null);
  const [languages, setLanguages] = useState([]);
  const [areReviewsLoading, setAreReviewsLoading] = useState(true);
  const [areLanguagesLoading, setAreLanguagesLoading] = useState(true);
  const isLoading = areReviewsLoading || areLanguagesLoading;

  const [date, setDate] = useState(null);
  const [participants, setParticipants] = useState(DEFAULT_PARTICIPANTS);
  const [languageId, setLanguageId] = useState(null);
  const [productId, setProductId] = useState(getDefaultProductId(experience, participants));

  const hasExperienceMap = hasMap(experience);
  const hasExperienceReviews = !areReviewsLoading && stats.totalReviews > 0;

  const images = experience.experiencesImages.map(({ image }) => image);
  const partnerImageUrl = experience.experiencesImages.length > 0 ? experience.experiencesImages[0].image.path : null;
  const maxParticipants = getExperienceMaxParticipants(experience);

  const reviews = stats?.reviews ? stats.reviews.map(review => ({
    author: getReviewAuthor(review?.review),
    rating: review.rating,
    comment: review.comment,
    createdAt: review.createdAt,
    provider: review?.review?.provider
  })) : [];

  async function fetchStats() {
    try {
      const stats = await getExperienceStats(experience.id);
      setStats(stats);
      setAreReviewsLoading(false);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchExperience.error')));
    }
  }

  async function fetchLanguages() {
    try {
      const { list } = await getLanguages();
      setLanguages(list);
      setAreLanguagesLoading(false);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchLanguages.error')));
    }
  }

  useEffect(() => {
    fetchStats();
    fetchLanguages();
  }, [experience]);

  return (
    <TabPanel>
      {
        isLoading ? (
          <Loader h={400} />
        ) : (
          <VStack maxW="1184px" mx="auto" position="relative" px={8} spacing={8} alignItems="flex-start">
            <ExperienceHeader
              title={experience.name}
              images={images}
            />

            <HStack spacing={16} w="100%" alignItems="stretch">
              <ExperienceDescription
                description={{ content: experience.description }}
                maxDuration={experience.maxDuration}
                maxParticipants={maxParticipants}
                type={experience.type}
                highlights={experience.highlights}
                included={experience.included}
                requirements={experience.requirementsList}
                partnerImageUrl={partnerImageUrl}
                partnerName={experience.partner?.name}
                partnerDescription={experience.partner?.description}
                partnersProvidersInformation={experience.partner?.partnersProvidersInformation ?? []}
              />

              <VStack w="100%" maxW={500} spacing={5}>
                <FormTabs>
                  <InfoStep
                    type={experience.type}
                    products={experience.products}
                    productId={productId}
                    participants={participants}
                    targetCurrency={{ id: 'EUR', targetExchangeRates: [] }}
                    date={date}
                    languages={languages}
                    languageId={languageId}
                    minDuration={experience.minDuration}
                    bookingDeadline={experience.bookingDeadline}
                    isGeneric={false}
                    isSubmitDisabled={true}
                    onParticipantsChange={setParticipants}
                    onDateChange={setDate}
                    onTimeChange={() => null}
                    onLanguageChange={setLanguageId}
                    onProductChange={setProductId}
                    onCityChange={() => null}
                  />
                </FormTabs>
              </VStack>
            </HStack>

            { hasExperienceMap && <ExperienceMap address={experience.partner.address} /> }

            {
              hasExperienceReviews &&
                <ExperienceReviews
                  rating={{ average: rating.average, count: stats.totalReviews }}
                  reviews={reviews}
                  reviewsCountGroupByRating={stats.reviewsCountGroupByRating}
                  partnersProvidersInformation={partner.partnersProvidersInformation}
                />
            }

            <ExperienceInfoAndPolicies
              importantInformation={experience.importantInformation}
              wheelchairAccessible={experience.wheelchairAccessible}
              pregnantAccessible={experience.pregnantAccessible}
            />
          </VStack>
        )
      }
    </TabPanel>
  );
}