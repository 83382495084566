import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, VStack, useDisclosure } from '@chakra-ui/react';
import { ExperienceReviewWithRelations } from '@jurnee/common/src/entities/ExperienceReview';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../components/buttons/SecondaryButton';
import { ReviewCard } from '../components/reviews/ReviewCard';
import { getReviewAuthor } from '../utils/reviews';

interface Props {
  reviews: ExperienceReviewWithRelations[];
}

export function ReviewsDrawer(props: Props) {
  const { t } = useTranslation('experience');

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SecondaryButton alignSelf="center" colorScheme="gray" onClick={onOpen}>
        { t('details.reviews.button', { count: props.reviews.length }) }
      </SecondaryButton>

      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { t('drawers.reviews.title') }
          </DrawerHeader>

          <DrawerBody w="100%" p={5}>
            <VStack spacing={5}>
              {
                props.reviews.map((review, index) => (
                  <ReviewCard
                    key={index}
                    author={getReviewAuthor(review?.review)}
                    rating={review.rating}
                    comment={review.comment}
                    createdAt={review.createdAt}
                    provider={review?.review?.provider}
                  />
                ))
              }
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}