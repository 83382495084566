import { Input } from '@chakra-ui/react';
import { useRef } from 'react';
import { ContentType, DOCUMENT_CONTENT_TYPES, IMAGE_CONTENT_TYPES } from '../../../constants/ContentTypes';
import { resizeImage } from '../../../utils/images';
import { Icon } from '../../Icon';

interface Props {
  imageMaxWidth: number;
  onAddFile(file: File): void;
}

export function AttachmentInput({ imageMaxWidth, onAddFile }: Props) {
  const fileInput = useRef(null);

  function handleClick() {
    fileInput.current.click();
  }

  async function handleFile(file: File) {
    if (!file) {
      return null;
    }

    if (IMAGE_CONTENT_TYPES.includes(file.type as ContentType)) {
      const resizedImage = await resizeImage(file, imageMaxWidth);
      return onAddFile(resizedImage);
    }

    return onAddFile(file);
  }

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    e.stopPropagation();
    e.preventDefault();

    const [file] = e.target.files;
    await handleFile(file);

    fileInput.current.value = null;
  }

  return (
    <>
      <Icon
        icon="paperclip"
        color="gray.400"
        size={4}
        cursor="pointer"
        _hover={{ color: 'gray.500' }}
        _active={{ color: 'gray.600' }}
        onClick={handleClick}
      />

      <Input
        type="file"
        accept={DOCUMENT_CONTENT_TYPES.join(', ')}
        onChange={handleChange}
        ref={fileInput}
        hidden
      />
    </>
  );
}