import contentTypes from '../constants/content-types.json';

export type ContentType = keyof typeof contentTypes;

export const IMAGE_CONTENT_TYPES: ContentType[] = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp'
];

export const DOCUMENT_CONTENT_TYPES: ContentType[] = [
  ...IMAGE_CONTENT_TYPES,
  'application/pdf'
];

export const ATTACHMENT_CONTENT_TYPES: ContentType[] = [
  ...DOCUMENT_CONTENT_TYPES,
  'application/postscript',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/svg+xml',
  'text/csv'
];