import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../../entities/Experience';
import { getExperienceMaxParticipants } from '../../../../utils/experiences';

interface Props {
  experience: ExperienceJSON;
}

export function Specifications({ experience }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'specs' });

  const maxParticipants = getExperienceMaxParticipants(experience);

  return (
    <HStack spacing={8}>
      {
        experience.maxDuration > 0 &&
          <HStack spacing={2}>
            <Icon icon="time" />
            <Text fontSize={14}>
              { t('durationInMin', { maxDuration: experience.maxDuration }) }
            </Text>
          </HStack>
      }
      {
        maxParticipants > 0 &&
          <HStack spacing={2}>
            <Icon icon="group" />
            <Text fontSize={14}>
              { t('participants', { maxParticipants }) }
            </Text>
          </HStack>
      }
    </HStack>
  );
}