import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../../entities/Experience';
import { sanitize } from '../../../../utils/strings';
import { Highlights } from './Highlights';
import { Specifications } from './Specifications';

interface Props {
  experience: ExperienceJSON;
}

export function Content({ experience }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.description' });

  const hasHightlights = experience.highlights.length > 0;

  return (
    <VStack w="100%" spacing={5} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Heading>{t('title')}</Heading>

        <Specifications experience={experience} />
      </HStack>

      <Text dangerouslySetInnerHTML={{ __html: sanitize(experience.description) }} whiteSpace="pre-line" />

      { hasHightlights && <Highlights experience={experience} /> }
    </VStack>
  );
}