import { PlaceAddressDTO } from '../dtos/places';
import AddressEntity, { AddressJSON } from '../entities/Address';
import LocationEntity from '../entities/Location';
import { isEmpty } from './strings';

export function formatAddress(address: Omit<(AddressJSON | AddressEntity), 'countryCode'>) {
  const parts: string[] = [];

  if (!isEmpty(address.address)) {
    parts.push(address.address);
  }

  if (address.additionalInformation && !isEmpty(address.additionalInformation)) {
    parts.push(address.additionalInformation);
  }

  if (!isEmpty(address.postalCode) && !isEmpty(address.city)) {
    parts.push(`${address.postalCode} ${address.city}`);
  } else if (!isEmpty(address.city) && !isEmpty(address.country)) {
    parts.push(address.city, address.country);
  } else if (!isEmpty(address.city)) {
    parts.push(address.city);
  }

  return parts.join(', ');
}

export function getAddressFromPlaceAddress(placeAddress: PlaceAddressDTO) {
  return {
    address: placeAddress.street,
    city: placeAddress.city,
    postalCode: placeAddress.postalCode,
    country: placeAddress.country,
    countryCode: placeAddress.countryCode ?? '',
    lat: placeAddress.latitude,
    long: placeAddress.longitude
  };
}

export function getCountryByCountryCode(countryCode: LocationEntity['countryCode']) {
  if (!countryCode || isEmpty(countryCode)) {
    return;
  }

  const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });

  return countryNames.of(countryCode);
}

export function getAddressFromPlaceAddressDTO(address: PlaceAddressDTO) {
  return {
    address: address.street ?? '',
    city: address.city,
    country: address.country ?? '',
    countryCode: address.countryCode ?? '',
    postalCode: address.postalCode ?? '',
    lat: address.latitude,
    long: address.longitude
  };
}

export function getCityAndCountry(address: Omit<(AddressJSON | AddressEntity), 'countryCode'>) {
  return [address.city, address.country].join(', ');
}