import { ContentType } from '@jurnee/common/src/constants/ContentTypes';
import { getRandomRequestId, getRandomSessionId } from '../utils/core';
import { Logger, logger } from './Logger';

const API_URL = process.env.API_URL;

interface ApiOptions {
  logger: Logger;
}

interface OptionalHeaders {
  'Content-Type': ContentType;
  'X-Filename': string;
}

class Api extends EventTarget {

  private token: string = localStorage.getItem('token');

  private sessionId: string = getRandomSessionId();

  private logger: Logger;

  constructor(options: ApiOptions) {
    super();
    this.logger = options.logger;
  }

  setToken(token: string) {
    this.token = token;
  }

  getHeaders(requestId: string, optionalHeaders?: OptionalHeaders) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...optionalHeaders
    };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    }

    headers['X-Request-ID'] = requestId;

    headers['X-Session-ID'] = this.sessionId;

    return headers;
  }

  private async getData(resp: Response) {
    try {
      const data = await resp.json();
      return data;
    } catch (e) {
      return {};
    }
  }

  private async fetch(url: URL, props: RequestInit, optionalHeaders?: OptionalHeaders): Promise<any> {
    const requestId = getRandomRequestId();
    const headers = this.getHeaders(requestId, optionalHeaders);

    const context = {
      method: props.method,
      path: url.pathname,
      sessionId: this.sessionId,
      requestId
    };

    this.logger.info('REQ', context);

    const resp = await fetch(url.toString(), { headers, ...props });

    const data = await this.getData(resp);

    if (!resp.ok) {
      this.logger.error('RES', { ...context, status: resp.status });

      if (resp.status === 401) {
        this.dispatchEvent(new Event('unauthorized'));
      }

      throw new Error(data.message);
    }

    this.logger.info('RES', { ...context, status: resp.status });

    return data;
  }

  async post(path: string, payload = {}): Promise<any> {
    return this.fetch(new URL(path, API_URL), {
      method: 'POST',
      body: JSON.stringify(payload)
    });
  }

  async uploadFile(path: string, file: File): Promise<any> {
    return this.fetch(new URL(path, API_URL), {
      method: 'POST',
      body: file
    }, {
      'Content-Type': file.type as ContentType,
      'X-Filename': file.name
    });
  }

  async delete(path: string, payload = {}) {
    return this.fetch(new URL(path, API_URL), {
      method: 'DELETE',
      body: JSON.stringify(payload)
    });
  }

  async put(path: string, payload = {}) {
    return this.fetch(new URL(path, API_URL), {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
  }

  async get(path: string) {
    return this.fetch(new URL(path, API_URL), {
      method: 'GET'
    });
  }

}

export const api = new Api({
  logger
});