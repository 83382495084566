import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  body: {
    backgroundColor: 'gray.50',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.200',
    borderTop: '1px solid',
    borderTopColor: 'gray.200',
    padding: '20px'
  },
  closeButton: {
    fontSize: '2xs',
    top: '6px'
  },
  dialog: {
    borderRadius: '8px',
    overflow: 'hidden'
  },
  footer: {
    padding: '10px 20px'
  },
  header: {
    fontSize: '16px',
    padding: '10px 20px'
  }
});

const threeXl = definePartsStyle({
  dialog: {
    maxWidth: '800px',
  }
});

const fiveXl = definePartsStyle({
  dialog: {
    maxWidth: '1040px',
  }
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    '3xl': threeXl,
    '5xl': fiveXl
  }
});