import { Badge, HStack, Text } from '@chakra-ui/react';
import { Icon, IconKey } from '@jurnee/common/src/components/Icon';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

interface Props {
  to: string;
  icon: IconKey;
  label: React.ReactNode;
  count?: number;
}

export function NavbarLink({ to, icon, label, count }: Props) {
  return (
    <NavLink className="nav-link" to={to} key={icon}>
      <HStack h="32px" alignItems="center" px={3} justifyContent="space-between">
        <HStack spacing={2}>
          <Icon icon={icon} size={5} />
          <Text noOfLines={1}>{label}</Text>
        </HStack>

        { count > 0 && <Badge variant="pink" size="md">{count}</Badge> }
      </HStack>
    </NavLink>
  );
}