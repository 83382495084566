import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

export class PrimaryButton extends React.PureComponent<ButtonProps> {

  get buttonProps() {
    const color = this.props.colorScheme ?? 'blue';

    if (color === 'black') {
      return {
        bg: 'black',
        color: 'white',
        _hover: { background: 'blackAlpha.700' },
        _active: { background: 'blackAlpha.800' }
      };
    }

    return {
      bg: `${color}.400`,
      color:'white',
      _hover: { background: `${color}.600` },
      _active: { background: `${color}.700` }
    };
  }

  render() {
    return (
      <Button {...this.buttonProps} {...this.props}>
        { this.props.children }
      </Button>
    );
  }

}