import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const simple = definePartsStyle({
  table: {
    bgColor: 'white',
    border: '1px solid',
    borderCollapse: 'separate',
    borderColor: 'gray.200',
    borderRadius: 4,
    borderSpacing: 0
  },
  td: {
    borderColor: 'gray.200',
    height: 12,
    paddingX: 5,
    paddingY: 1
  },
  th: {
    borderColor: 'gray.200',
    color: 'black',
    fontSize: '14px',
    letterSpacing: 'normal',
    paddingX: 5,
    paddingY: 1,
    textTransform: 'none'
  },
  tr: {
    height: 12
  }
});

export const Table = defineMultiStyleConfig({
  variants: { simple }
});