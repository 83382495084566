import { Box, ColorProps, VStack } from '@chakra-ui/react';

interface Props {
  size: 'sm' | 'lg';
  color: ColorProps['color'];
}

export function Dots({ size, color}: Props) {
  const w = size === 'sm' ? '2px' : '3px';
  const space = size === 'sm' ? '2px' : '4px';
  const radius = size === 'sm' ? 0 : 4;

  return (
    <VStack spacing={space}>
      {[1, 2, 3].map((i) => <Box key={i} bg={color} w={w} h={w} borderRadius={radius} />)}
    </VStack>
  );
}