import { FormControl, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { Language } from '@jurnee/common/src/entities/Language';
import * as React from 'react';

interface OwnProps extends SelectProps {
  label: string;
  languages: Language[];
  languageId: number;
  isRequired?: boolean;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

export class LanguageSelect extends React.Component<OwnProps> {

  get options() {
    return this.props.languages.map((language, i) => (
      <option key={i} value={language.id}>{language.name}</option>
    ));
  }

  render() {
    const { label, languages, languageId, isRequired, onChange, ...inheritedProps } = this.props;

    return (
      <FormControl id="languageId" isRequired={isRequired}>
        <FormLabel>{label}</FormLabel>
        <Select bgColor="white" defaultValue={languageId} onChange={onChange} {...inheritedProps}>
          { this.options }
        </Select>
      </FormControl>
    );
  }

}