import { Divider, HStack } from '@chakra-ui/react';
import { ExperienceJSON } from '../../../entities/Experience';
import { CancellationPolicy } from './CancellationPolicy';
import { ImportantInfo } from './ImportantInfo';

interface Props {
  experience: ExperienceJSON;
}

export function ExperienceInfoAndPolicies({ experience }: Props) {
  return (
    <>
      <Divider />

      <HStack w="100%" alignItems="flex-start">
        <ImportantInfo experience={experience} />
        <CancellationPolicy />
      </HStack>
    </>
  );
}