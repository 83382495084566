import { HStack, Radio, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  label: string;
  labelInfo?: string | React.ReactNode;
  isSelected: boolean;
  onSelect(): void;
  children?: React.ReactNode;
  isInvalid?: boolean
}

export default class RadioCard extends React.PureComponent<Props> {

  static readonly defaultProps = { bg: 'gray.50', borderColor: 'gray.200', _hover: { borderColor: 'blue.100' } };
  static readonly selectedProps = { bg: 'blue.50', borderColor: 'blue.400' };
  static readonly invalidProps = { bg: 'red.50', borderColor: 'red.400' };
  static readonly disabledProps = { bg: 'gray.50', borderColor: 'gray.200' };

  get children() {
    if (!this.props.children) {
      return;
    }

    if (!this.props.isSelected) {
      return;
    }

    return (
      <VStack w="100%" pl={5} spacing="10px">
        { this.props.children }
      </VStack>
    );
  }

  get isSelectedInvalid() {
    return this.props.isSelected && this.props.isInvalid;
  }

  get isDisabled() {
    return !this.props.isSelected && this.props.isInvalid;
  }

  get cardProps() {
    if (this.isSelectedInvalid) {
      return RadioCard.invalidProps;
    }

    if (this.isDisabled) {
      return RadioCard.disabledProps;
    }

    if (this.props.isSelected) {
      return RadioCard.selectedProps;
    }

    return RadioCard.defaultProps;
  }

  onSelect = () => {
    if (this.props.isInvalid) {
      return;
    }

    this.props.onSelect();
  };

  render() {
    return (
      <VStack
        w="100%"
        p={4}
        border="1px solid"
        borderRadius={8}
        spacing="10px"
        alignItems="flex-start"
        cursor={this.isDisabled ? 'not-allowed' : 'pointer'}
        onClick={this.onSelect}
        {...this.cardProps}
      >
        <Radio
          w="100%"
          colorScheme={this.isSelectedInvalid ? 'red' : 'blue'}
          isChecked={this.props.isSelected}
          isDisabled={this.isDisabled}
          variant="radioCard"
        >
          <HStack w="100%" justifyContent="space-between">
            <Text fontSize={14} noOfLines={1}>{this.props.label}</Text>
            { this.props.labelInfo }
          </HStack>
        </Radio>

        { this.children }
      </VStack>
    );
  }

}