import { Image, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getIsAuthenticatedSelector, getShouldAuthenticateSelector } from 'store/auth/auth.selectors';
import { connectAs, login } from 'store/auth/auth.thunks';
import { sendMagicLink } from '../api/auth';
import { useAppDispatch } from '../store';

export function Login() {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const shouldAuthenticate = useSelector(getShouldAuthenticateSelector);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const location = useLocation();
  const toast = useToast();
  const search = new URLSearchParams(location.search);

  useEffect(() => {
    if (search.has('email')) {
      setEmail(search.get('email'));
    }

    if (shouldAuthenticate) {
      if (search.has('connectAs')) {
        dispatch(connectAs(search.get('connectAs')));
      } else if (search.has('magic-token')) {
        dispatch(login(search.get('magic-token')));
      }
    }
  }, [shouldAuthenticate]);

  if (isAuthenticated) {
    const redirect = search.has('redirect') ? search.get('redirect') : '/';

    return <Navigate to={redirect} />;
  }

  async function onClick() {
    try {
      await sendMagicLink(email);

      toast({
        title: 'Magic link sent',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'An error occured',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  return (
    <VStack justifyContent="center" alignItems="center" w="100%" h="100%" spacing={10}>
      <Image src="/assets/images/logo_full.svg" w={200} />

      <VStack w={400} spacing={5} bg="white" p={5} border="1px solid" borderColor="gray.200" borderRadius={8}>
        <InputFormControl name="email" value={email} type="email" onChange={setEmail}/>
        <PrimaryButton w="100%" onClick={onClick}>Sign in</PrimaryButton>
      </VStack>
    </VStack>
  );
}