import { Box, BoxProps, Spinner } from '@chakra-ui/react';
import * as React from 'react';

interface Props extends BoxProps {
  overlay?: boolean;
}

export class Loader extends React.PureComponent<Props> {

  render() {
    const { overlay, ...inheritedProps } = this.props;

    const defaultProps = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      w: '100%',
      h: '100%'
    };

    if (overlay) {
      Object.assign(defaultProps, {
        position: 'absolute',
        background: 'whiteAlpha.700',
        borderRadius: 'lg',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      });
    }

    const props = { ...defaultProps, ...inheritedProps };

    return (
      <Box {...props}>
        <Spinner thickness="4px" size="lg" color="blue.400"/>
      </Box>
    );
  }

}