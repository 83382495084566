import { Box, HStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ExperienceRatingJSON } from '@jurnee/common/src/entities/Experience';

interface Props {
  rating: ExperienceRatingJSON;
}

export function ExperienceAverageRating({ rating }: Props) {
  return (
    <HStack spacing={1}>
      <Icon mt="-1px" icon="star" size={4} />
      {
        rating?.total > 0 ? (
          <>
            <Box fontSize={14} fontWeight={500} lineHeight="16px">{rating.averageRating.toFixed(1)}</Box>
            <Box fontSize={14} fontWeight={500} lineHeight="16px">({rating.total})</Box>
          </>
        ) : (
          <Box fontSize={14} lineHeight="16px">-</Box>
        )
      }
    </HStack>
  );
}