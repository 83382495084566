import { FormControl, FormLabel, NumberInputProps } from '@chakra-ui/react';
import { NumberInput } from '@jurnee/common/src/components/NumberInput';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultParticipants?: number;
  maxParticipants?:number;
  label: string;
  isParticipantsRangeValid: boolean;
  isRequired?: boolean;
  size?: NumberInputProps['size'];
  onChange(participants: number): void;
}

export function ParticipantsInput(props: Props) {
  const { t } = useTranslation('common');

  function onChange(value: string) {
    const participants = Number(value);

    if (isNaN(participants)) {
      return;
    }

    props.onChange(participants);
  }

  return (
    <FormControl id="participants" isInvalid={!props.isParticipantsRangeValid} isRequired={props.isRequired}>
      <FormLabel>{props.label}</FormLabel>
      <NumberInput
        onChange={onChange}
        min={1}
        max={props.maxParticipants}
        defaultValue={props.defaultParticipants}
        clampValueOnBlur={false}
        placeholder={t('fields.participants.placeholder')}
        size={props.size ?? 'sm'}
      />
    </FormControl>
  );
}