import { FormControl, FormHelperText, FormLabel, Input, InputProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  value: string | null;
  hasPlaceholder?: boolean;
  hasHelper?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  type?: InputProps['type'];
  size?: InputProps['size'];
  onChange(value: string): void;
}

export function InputFormControl(props: Props) {
  const { t } = useTranslation('common');

  return (
    <FormControl isRequired={props.isRequired ?? false}>
      <FormLabel>{t(`fields.${props.name}.label`)}</FormLabel>
      <Input
        type={props.type ?? 'text'}
        name={props.name}
        value={props.value ?? ''}
        placeholder={props.hasPlaceholder ? t(`fields.${props.name}.placeholder`) : null}
        onChange={(e) => props.onChange(e.target.value)}
        size={props.size ?? 'md'}
        isDisabled={props.isDisabled}
      />
      {props.hasHelper && <FormHelperText mt="6px">{t(`fields.${props.name}.helper`)}</FormHelperText>}
    </FormControl>
  );
}