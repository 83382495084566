export function groupBy<T extends Record<K, string | number>, K extends string>(list: T[], key: K): { [key: string]: T[] } {
  return list.reduce((map, item) => {
    map[item[key]] ? map[item[key]].push(item) : map[item[key]] = [item];

    return map;
  }, {} as Record<T[K], T[]>);
}

export function sortBy<T, K extends keyof T>(list: T[], key: K): T[] {
  return [...list].sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }

    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  });
}

export function sortByDate<T extends Record<K, string | Date>, K extends string, O extends 'asc' | 'desc'>(list: T[], key: K, order?: O): T[] {
  return [...list].sort((a,b) => {
    if (order === 'desc') {
      return new Date(b[key]).getTime() - new Date(a[key]).getTime();
    }

    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
  });
}

export function sortAlphabeticallyBy<T extends Record<K, string>, K extends string>(list: T[], key: K, order: 'asc' | 'desc' = 'asc'): T[] {
  return [...list].sort((a, b) => {
    if (order === 'desc') {
      return b[key].localeCompare(a[key]);
    }
    return a[key].localeCompare(b[key]);
  });
}

export function sortByDateAndAlphabetically<T extends Record<D, string | Date> & Record<S, string>, D extends string, S extends string>(list: T[], dateKey: D, stringKey: S): T[] {
  return [...list].sort((a,b) => {
    const dateA = new Date(a[dateKey]).getTime();
    const dateB = new Date(b[dateKey]).getTime();

    if (dateA < dateB) {
      return -1;
    }

    if (dateB < dateA) {
      return 1;
    }

    return a[stringKey].localeCompare(b[stringKey]);
  });
}

export function haveSameContent(a: number[], b: number[]) {
  if (a.length !== b.length) {
    return false;
  }

  return b.every(value => a.includes(value)) && a.every(value => b.includes(value));
}

export function getById<T extends { id: number }>(list: T[], id: number): T | undefined {
  return list.find(item => item.id === id);
}