import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDetails } from '../../../entities/User';
import { InformationForm, Props as InformationFormProps } from './InformationForm';

interface Props {
  targetCurrency: UserDetails['currency'];
  children?: ReactNode;
  onInfoNext(): void;
}

export function ExperienceForm(props: Props & InformationFormProps) {
  const { t } = useTranslation('experience', { keyPrefix: 'form' });

  const [step, setStep] = useState(0);

  function onChangeTab(index: number) {
    if (index >= step) {
      return;
    }

    setStep(index);
  }

  function onInfoNext() {
    setStep(1);
    props.onInfoNext();
  }

  return (
    <Tabs
      bg="white"
      border="1px solid"
      borderColor="blue.50"
      borderRadius={8}
      defaultIndex={step}
      index={step}
      minW={450}
      p={5}
      position="sticky"
      top={10}
      w="100%"
      onChange={onChangeTab}
    >
      <TabList>
        <Tab noOfLines={1} w="50%" fontSize={14} px={0} pt={0}>
          { t('steps.information.tabLabel') }
        </Tab>

        <Tab noOfLines={1} w="50%" fontSize={14} px={0} pt={0}>
          { t('steps.event.tabLabel') }
        </Tab>
      </TabList>

      <TabPanels p={0}>
        <TabPanel>
          <InformationForm
            experience={props.experience}
            productId={props.productId}
            participants={props.participants}
            currency={props.currency}
            targetCurrency={props.targetCurrency}
            date={props.date}
            languages={props.languages}
            languageId={props.languageId}
            isSubmitDisabled={props.isSubmitDisabled}
            onParticipantsChange={props.onParticipantsChange}
            onDateChange={props.onDateChange}
            onTimeChange={props.onTimeChange}
            onLanguageChange={props.onLanguageChange}
            onProductChange={props.onProductChange}
            onCityChange={props.onCityChange}
            onInfoNext={onInfoNext}
          />
        </TabPanel>

        <TabPanel>
          { props.children }
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}