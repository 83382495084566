import { HStack, Menu, MenuButton, Spacer, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { CompanyJSON } from '../entities/Company';
import { PartnerJSON } from '../entities/Partner';
import { PartnerUserJSON } from '../entities/PartnerUser';
import { UserDetails } from '../entities/User';
import { isEmpty } from '../utils/strings';
import Avatar from './Avatar';
import { Icon } from './Icon';

interface OwnProps {
  organization: CompanyJSON | PartnerJSON;
  user: PartnerUserJSON | UserDetails;
  children: React.ReactNode;
}

type Props = OwnProps;

export function UserCard(props: Props) {
  const { user, organization } = props;

  const userLabel = !isEmpty(user.firstName) ? user.firstName : user.email;

  return (
    <Menu>
      <MenuButton _hover={{ bg: 'gray.10', boxShadow: '0 0 0 8px var(--chakra-colors-gray-10)' }} borderRadius={4}>
        <HStack w="100%" spacing={2} >
          <Avatar user={user} w="40px" h="40px" />

          <VStack spacing={1} alignItems="flex-start">
            <Text fontSize={14} lineHeight={1} noOfLines={1} maxW={125}>{userLabel}</Text>
            <Text fontSize={14} lineHeight={1} color="gray.400" noOfLines={1}>{organization.name}</Text>
          </VStack>

          <Spacer />

          <Icon icon='verticalDots' />
        </HStack>
      </MenuButton>

      {props.children}
    </Menu>
  );
}