import { Box, FormLabel, Text, VStack } from '@chakra-ui/react';
import { PriceInCurrency } from '@jurnee/common/src/components/PriceInCurrency';
import RadioCard from '@jurnee/common/src/components/RadioCard';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { getPackagePrice, getProductPrice, isCustomRequestProduct, sortByPackagePrice } from '@jurnee/common/src/utils/products';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserDetails } from '../entities/User';

interface OwnProps extends WithTranslation {
  currentProductId: ProductJSON['id'];
  products: ProductJSON[];
  quantity: number;
  targetCurrency: UserDetails['currency'];
  onChange(productId: ProductJSON['id']): void;
}

class PackageRadioGroup extends React.Component<OwnProps> {

  isSelected = ({ id }: Pick<ProductJSON, 'id'>) => {
    return this.props.currentProductId === id;
  };

  getDescription = ({ description }: Pick<ProductJSON, 'description'>) => {
    if (isEmpty(description)) {
      return null;
    }

    return <Text w="100%" pl={1} fontSize={14} color="gray.500">{description}</Text>;
  };

  get products() {
    const products = this.props.products.filter(product => !isCustomRequestProduct(product));

    return sortByPackagePrice(products, this.props.quantity);
  }

  get customRequestProduct() {
    const product = this.props.products.find(isCustomRequestProduct);

    if (!product) {
      return;
    }

    const description = isEmpty(product.description) ? this.props.t('form.steps.information.package.customRequest.description') : product.description;
    const labelInfo =
      <Text fontSize={14} textAlign="right" minW="130px">
        { this.props.t('form.steps.information.package.customRequest.labelInfo') }
      </Text>;

    return (
      <RadioCard
        key="customRequest"
        isSelected={this.isSelected(product)}
        onSelect={() => this.props.onChange(product.id)}
        label={this.props.t('form.steps.information.package.customRequest.label')}
        labelInfo={labelInfo}
        isInvalid={this.props.quantity > 0 && getProductPrice(product, this.props.quantity) === null}
      >
        <Text w="100%" pl={1} fontSize={14} color="gray.500">
          { description }
        </Text>
      </RadioCard>
    );
  }

  get radioCards() {
    const products = this.products.map(product => {
      const price = getPackagePrice(product, this.props.quantity);
      const labelInfo =
        <Text fontSize={14} textAlign="right" minW="85px">
          <PriceInCurrency
            value={price}
            currency={product.currency}
            targetCurrency={this.props.targetCurrency}
          />
        </Text>;

      return (
        <RadioCard
          key={product.id}
          isSelected={this.isSelected(product)}
          onSelect={() => this.props.onChange(product.id)}
          label={product.name}
          labelInfo={labelInfo}
          isInvalid={this.props.quantity > 0 && getProductPrice(product, this.props.quantity) === null}
        >
          { this.getDescription(product) }
        </RadioCard>
      );
    });

    return [...products, this.customRequestProduct];
  }

  render() {
    return (
      <Box w="100%">
        <FormLabel>{this.props.t('form.steps.information.package.label')}</FormLabel>

        <VStack alignItems="flex-start" spacing="10px">
          { this.radioCards }
        </VStack>
      </Box>
    );
  }

}

export default withTranslation('experience')(PackageRadioGroup);