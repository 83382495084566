import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

const baseStyle = definePartsStyle({
  body: {
    backgroundColor: 'gray.50',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.200',
    borderTop: '1px solid',
    borderTopColor: 'gray.200',
    padding: '20px'
  },
  closeButton: {
    fontSize: '2xs',
    top: '6px'
  },
  footer: {
    padding: '10px 20px'
  },
  header: {
    padding: '10px 20px',
    fontSize: 16
  }
});

const sm = definePartsStyle({
  dialog: {
    maxW: '400px'
  }
});

const md = definePartsStyle({
  dialog: {
    maxW: '470px'
  }
});

const lg = definePartsStyle({
  dialog: {
    maxW: '600px'
  }
});

const xl = definePartsStyle({
  dialog: {
    maxW: '720px'
  }
});

export const Drawer = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    sm,
    md,
    lg,
    xl
  },
  defaultProps: {
    size: 'sm'
  }
});