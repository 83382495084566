import { Box, HStack, Text } from '@chakra-ui/react';

interface Props {
  value: number;
  percentage: number;
  count: number;
}

export function ProgressBar({ value, percentage, count }: Props) {
  const colors = ['red.500', 'orange.300', 'yellow.300', 'green.300', 'teal.300'];
  const grayBarPercentage = 100 - percentage;

  return (
    <HStack key={value} w="100%" spacing="6px">
      <Text fontSize={14} fontWeight={700} lineHeight="14px">{value}</Text>
      { percentage > 0 && <Box w={`${percentage}%`} h="4px" minW="4px" bgColor={colors[value - 1]} borderRadius="3px" /> }
      { grayBarPercentage > 0 && <Box w={`${grayBarPercentage}%`} h="4px" minW="4px" bgColor="gray.200" borderRadius="3px" /> }
      <Text fontSize={14} color="gray.400" minW="20px" lineHeight="14px">{count}</Text>
    </HStack>
  );
}