import { FormControl, FormLabel, HStack, InputProps } from '@chakra-ui/react';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { add } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';
import { BookingDeadlineTooltip } from './BookingDeadlineTooltip';

interface Props {
  bookingDeadline: number;
  selected: Date | null;
  label: string;
  isRequired?: boolean
  inputProps?: InputProps;
  onChange(from: Date): void;
}

export function FromDatePicker(props: Props) {
  const { t } = useTranslation('common');
  const today = new Date();
  const minDate = add(today, props.bookingDeadline);
  const hasDeadlineTooltip = props.selected && props.selected < minDate;

  return (
    <FormControl id="date" isRequired={props.isRequired}>
      <HStack justifyContent="space-between">
        <FormLabel>{ props.label }</FormLabel>
        { hasDeadlineTooltip && <BookingDeadlineTooltip position="absolute" top={0} right={0} /> }
      </HStack>

      <DatePicker
        popperPlacement="bottom-start"
        dateFormat="dd MMM yyyy"
        selected={props.selected}
        minDate={today}
        placeholderText={t('fields.date.placeholder')}
        onChange={props.onChange}
        inputProps={props.inputProps}
      />
    </FormControl>
  );

}