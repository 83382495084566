import { FormControl, FormHelperText, FormLabel, Textarea, TextareaProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  value: string | null;
  hasHelper?: boolean;
  h?: TextareaProps['h'];
  isRequired?: boolean;
  isDisabled?: boolean;
  onChange(value: string): void;
}

export function TextareaFormControl(props: Props) {
  const { t } = useTranslation('common');
  const { name, value, hasHelper, isRequired, onChange, h } = props;

  return (
    <FormControl isRequired={isRequired ?? false}>
      <FormLabel>{t(`fields.${props.name}.label`)}</FormLabel>
      <Textarea
        size="sm"
        name={name}
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
        h={h ?? '120px'}
        isDisabled={props.isDisabled}
      />
      { hasHelper && <FormHelperText>{t(`fields.${name}.helper`)}</FormHelperText> }
    </FormControl>
  );
}