import { HStack, Text } from '@chakra-ui/react';
import { Icon, IconKey } from '@jurnee/common/src/components/Icon';

interface Props {
  icon: IconKey;
  colorScheme: string;
  content: string;
}

export function ListItemCard({ icon, colorScheme, content }: Props) {
  return (
    <HStack
      w="100%"
      alignItems="center"
      p={3}
      spacing="10px"
      bg="white"
      borderRadius={8}
      border="1px solid"
      borderColor="blue.50"
    >
      <Icon icon={icon} color={colorScheme} />
      <Text fontSize={14}>{content}</Text>
    </HStack>
  );
}