import { Select, SelectProps } from '@chakra-ui/react';
import { formatTimeShort } from '@jurnee/common/src/utils/dates';
import * as React from 'react';

const MORNING_OPENING = 9*60;
const EVENING_CLOSING = 22*60;
const MIN_SLOT_DURATION = 30;

export interface TimeSlot {
  from: string;
  to: string;
}

interface TimeSlotOption extends TimeSlot {
  label: string;
}

interface OwnProps extends SelectProps {
  onPick(slot: TimeSlot): void;
  date: Date;
  duration?: number;
  placeholder?: string;
  value?: string;
  width?: string;
  size?: SelectProps['size'];
}

export class TimeSlotSelector extends React.PureComponent<OwnProps> {

  getHourlyOptions(duration: number): TimeSlotOption[] {
    const options: TimeSlotOption[] = [];

    for (let i = MORNING_OPENING; i < EVENING_CLOSING; i += MIN_SLOT_DURATION) {
      const from = formatTimeShort(this.getDateAtMinutes(i));
      const to = formatTimeShort(this.getDateAtMinutes(i + duration));

      options.push({ from, to, label: from });
    }

    return options;
  }

  get options(): TimeSlotOption[] {
    return this.getHourlyOptions(this.props.duration ?? 0);
  }

  getDateAtMinutes(minutes: number): Date {
    const { date } = this.props;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, minutes, 0);
  }

  onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const option = value === '' ? { from: null as string, to: null as string } : this.options.find(({ from }) => from === value);
    const { from, to } = option;

    this.props.onPick({ from, to });
  };

  render() {
    const { borderColor } = this.props;

    return (
      <Select
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        w={this.props.width || '100%'}
        borderColor={borderColor}
        bgColor="white"
        value={this.props.value}
        size={this.props.size}
      >
        {...this.options.map(({ from, label }, i) => <option key={i} value={from}>{label}</option>)}
      </Select>
    );
  }

}