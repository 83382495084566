import { Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Currency } from '../../../../entities/Currency';
import { ProductJSON } from '../../../../entities/Product';
import { UserDetails } from '../../../../entities/User';
import { getMaxThreshold, isCustomRequestProduct, isParticipantsRangeValid } from '../../../../utils/products';
import { PriceInCurrency } from '../../../PriceInCurrency';

interface Props {
  product: ProductJSON;
  participants: number;
  price: number;
  currency: Currency;
  targetCurrency: UserDetails['currency'];
}

export function PriceLine(props: Props) {
  const { t } = useTranslation('common');

  if (!isParticipantsRangeValid(props.product, props.participants)) {
    return (
      <Text fontSize={14} lineHeight="26px" color="red.500">
        <Trans
          i18nKey='experience:form.steps.information.participants.error'
          values={{ min: 1, max: getMaxThreshold(props.product) }}
        />
      </Text>
    );
  }

  if (isCustomRequestProduct(props.product)) {
    return null;
  }

  return (
    <>
      <Text fontSize={24} fontWeight={700}>
        <PriceInCurrency
          value={props.price}
          currency={props.currency}
          targetCurrency={props.targetCurrency}
        />
      </Text>

      <Text lineHeight="26px" color="gray.400" h={5}>
        { t('excludedTax') }
      </Text>
    </>
  );
}