import { Divider, HStack, Input, Select } from '@chakra-ui/react';
import { Currency, CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { useState } from 'react';

export interface AmountInputData {
  amount: number;
  currency: string;
}

interface Props {
  currencies: (keyof CurrencyExchangeRates)[];
  placeholder: string;
  defaultAmount?: number;
  defaultCurrency?: string;
  isInvalid?: boolean
  onChange(data: AmountInputData): void;
}

export default function AmountInput(props: Props) {
  const [amount, setAmount] = useState<number>(props.defaultAmount);
  const [currency, setCurrency] = useState<string>(props.defaultCurrency || 'EUR');

  function handleAmountChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    const newAmount = Number(target.value);

    if (isNaN(newAmount)) {
      return;
    }

    setAmount(newAmount);

    props.onChange({ amount: newAmount, currency });
  }

  function handleCurrencyChange({ target }: React.ChangeEvent<HTMLSelectElement>) {
    const newCurrency = target.value as Currency;

    setCurrency(newCurrency);

    props.onChange({ amount, currency: newCurrency });
  }

  return (
    <HStack
      w="100%"
      height="32px"
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
      spacing={3}
      py="10px"
      position="relative"
      _invalid={{ borderColor: 'red.500', boxShadow: '0 0 0 1px var(--chakra-colors-red-500)' }}
      _focusWithin={{ zIndex: 1, borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)' }}
      aria-invalid={props.isInvalid}
    >
      <Input
        type="number"
        variant="unstyled"
        paddingInlineStart={3}
        paddingInlineEnd={3}
        fontSize={14}
        placeholder={props.placeholder}
        defaultValue={amount}
        onChange={handleAmountChange}
      />

      <Divider orientation="vertical" color="gray.200" />

      <Select
        w="150px"
        variant="unstyled"
        fontSize={14}
        defaultValue={currency}
        onChange={handleCurrencyChange}
      >
        {
          props.currencies.map((currency, i) => (
            <option key={i} value={currency}>{currency}</option>
          ))
        }
      </Select>
    </HStack>
  );
}