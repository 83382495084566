import { HStack, StackProps, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';

export function SeePhotosButton(props: StackProps) {
  const { t } = useTranslation('experience', { keyPrefix: 'buttons' });

  return (
    <HStack
      bg="white"
      _hover={{ bg: 'gray.50' }}
      _active={{ bg: 'gray.100' }}
      cursor="pointer"
      alignItems="center"
      spacing="4px"
      px={2}
      py={1}
      position="absolute"
      bottom={5}
      right={5}
      borderRadius={4}
      userSelect="none"
      {...props}
    >
      <Icon icon="grid2" />
      <Text fontSize={14} fontWeight={500}>{t('seeAllPhotos')}</Text>
    </HStack>
  );
}