import { Box, IconProps, Tooltip } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';

export function BookingDeadlineTooltip(props: IconProps) {
  const { t } = useTranslation('common');

  return (
    <Tooltip label={t('fields.date.tooltip')} minW={380} size="md">
      <Box>
        <Icon icon="warning" size={5} color="orange.400" {...props} />
      </Box>
    </Tooltip>
  );
}