import { SearchSelect } from '@jurnee/common/src/components/Select';
import list from './list';

interface Props {
  onChange(data: { label: string, value: string }): void;
  value: string;
}

export function CountrySelector({ value, onChange }: Props) {
  return (
    <SearchSelect<string>
      options={list}
      openMenuOnFocus={true}
      maxMenuHeight={200}
      placeholder="Select"
      onChange={onChange}
      value={list.find(x => x.value === value)}
    />
  );
}