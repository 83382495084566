import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Textarea,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import { PrimaryButton } from '@jurnee/common/src/components/buttons/PrimaryButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { ReactElement, cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  message: string;
  reasonLabel: string;
  reasonPlaceholder: string;
  button: string;
  children: ReactElement;
  isReasonRequired?: boolean;
  onDecline(declinedReason: string): Promise<void>;
}

export function DeclineModal(props: Props) {
  const { t } = useTranslation('common');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [declinedReason, setDeclineReason] = useState<string>(null);
  const [isSaving, setIsSaving] = useState(false);

  async function onDecline() {
    setIsSaving(true);

    try {
      await props.onDecline(declinedReason);

      setIsSaving(false);
      onClose();
    } catch(err) {
      setIsSaving(false);
    }
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <AlertDialog leastDestructiveRef={null} isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            { props.title }
            <AlertDialogCloseButton />
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack spacing={3}>
              <Text w="100%" whiteSpace="pre-line">{props.message}</Text>

              <FormControl isRequired={props.isReasonRequired}>
                <FormLabel>
                  { props.reasonLabel }
                </FormLabel>

                <Textarea
                  size="sm"
                  h="80px"
                  resize="none"
                  value={declinedReason || ''}
                  placeholder={props.reasonPlaceholder}
                  onChange={(e) => setDeclineReason(e.target.value)}
                />
              </FormControl>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="gray" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="pink" size="sm" onClick={onDecline} isLoading={isSaving} isDisabled={props.isReasonRequired && isEmpty(declinedReason)}>
                { props.button }
              </PrimaryButton>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}