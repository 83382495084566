import { MenuItem, Text } from '@chakra-ui/react';
import { Icon, IconKey } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

interface OwnProps {
  icon?: IconKey;
  label: string;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler;
}

export class MenuItemWrapper extends React.PureComponent<OwnProps> {

  render() {
    return (
      <MenuItem onClick={this.props.onClick} isDisabled={this.props.isDisabled}>
        {this.props.icon ? <Icon size={5} icon={this.props.icon} /> : null}
        <Text ml={this.props.icon ? 3 : 0} fontSize={14} textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
          {this.props.label}
        </Text>
      </MenuItem>
    );
  }

}