import { Center, HStack, Spacer, Text } from '@chakra-ui/react';
import Select, { GroupBase, OptionProps, Props, SingleValueProps, StylesConfig, ValueContainerProps, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Icon, IconKey } from './Icon';

const customStyles: StylesConfig = {
  control: (provided: Record<string, string>) => ({
    ...provided,
    border: '1px solid var(--chakra-colors-gray-200)',
    boxShadow: 'initial',
    borderRadius: '8px',
    ':hover': {
      border: '1px solid var(--chakra-colors-gray-300)'
    },
    ':focus-within': {
      zIndex: 1,
      borderColor: 'var(--chakra-colors-blue-500)',
      boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)'
    }
  }),
  indicatorSeparator: (provided: Record<string, string>) => ({
    ...provided,
    background: 'var(--chakra-colors-gray-200)'
  }),
  dropdownIndicator: (provided: Record<string, string>) => ({
    ...provided,
    color: 'var(--chakra-colors-black)',
    padding: '9px 11px',
    width: '34px',
  }),
  menuList: (provided: Record<string, string>) => ({
    ...provided,
    padding: '0',
    boxSizing: 'border-box',
    overflowX: 'hidden'
  }),
  multiValue: (provided: Record<string, string>) => ({
    ...provided,
    color: 'var(--chakra-colors-blue-400)',
    background: 'var(--chakra-colors-blue-50)',
    borderRadius: '4px',
  }),
  multiValueLabel: (provided: Record<string, string>) => ({
    ...provided,
    color: 'var(--chakra-colors-blue-400)',
  }),
  placeholder: (provided: Record<string, string>) => ({
    ...provided,
    color: 'var(--chakra-colors-gray-500)',
  }),
  valueContainer: (provided: Record<string, string>) => ({
    ...provided,
    padding: '0 15px'
  }),
  option: (provided: Record<string, string>, { isDisabled, isFocused, isSelected }: OptionProps) => ({
    ...provided,
    color: isDisabled ? 'var(--chakra-colors-gray-400)' : 'black',
    maxWidth: '100%',
    lineHeight: '24px',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: isFocused || isSelected ? 'var(--chakra-colors-gray-50)' : 'transparent',
    ':active': { backgroundColor: 'var(--chakra-colors-gray-100)' }
  }),
  singleValue: (provided: Record<string, string>, { selectProps }: SingleValueProps) => ({
    ...provided,
    display: selectProps.menuIsOpen ? 'none' : 'block'
  }),
};

const searchIconStyles = {
  ...customStyles,
  control: (provided: Record<string, string>) => ({
    ...provided,
    border: '1px solid var(--chakra-colors-gray-200)',
    boxShadow: 'initial',
    borderRadius: '4px',
    minHeight: '32px',
    fontWeight: 500,
    ':hover': { borderColor: 'var(--chakra-colors-gray-300)' }
  }),
  option: (provided: Record<string, string>, { isFocused, isSelected }: OptionProps) => ({
    ...provided,
    fontWeight: 500,
    color: 'black',
    backgroundColor: isFocused || isSelected ? 'var(--chakra-colors-gray-50)' : 'transparent',
    ':active': { backgroundColor: 'var(--chakra-colors-gray-100)' }
  }),
  valueContainer: (provided: Record<string, string>) => ({
    ...provided,
    padding: '0 12px 0 40px'
  }),
  input: (provided: Record<string, string>) => ({
    ...provided,
    margin: 0
  }),
  placeholder: (provided: Record<string, string>) => ({
    ...provided,
    color: 'var(--chakra-colors-gray-500)',
    marginLeft: 0,
    marginRight: 0
  }),
  singleValue: (provided: Record<string, string>, { selectProps }: SingleValueProps) => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
    display: selectProps.menuIsOpen ? 'none' : 'block'
  }),
};

const multiCustomStyles = {
  ...customStyles,
  input: (provided: Record<string, string>) => ({
    ...provided,
    padding: '0 10px'
  }),
  placeholder: (provided: Record<string, string>) => ({
    ...provided,
    color: 'var(--chakra-colors-gray-500)',
    padding: '0 10px'
  }),
  valueContainer: (provided: Record<string, string>) => ({
    ...provided,
    padding: '5px'
  }),
};

export function Option<T>(props: OptionProps<{ label: string, value: T, sublabel?: string, isDisabled?: boolean, img?: string }>) {
  return (
    <components.Option {...props}>
      <HStack spacing={2} px={1}>
        {
          props.data.img &&
            <Center
              minW={8}
              minH={8}
              bgColor="gray.50"
              borderRadius="2px"
              bgImage={`linear-gradient(rgba(30, 0, 45, 0.30), rgba(30, 0, 45, 0.30)), url('${props.data.img}')`}
              bgPosition="center"
              bgSize="cover"
            />
        }

        <Text
          fontSize={14}
          lineHeight="24px"
          maxWidth="100%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          { props.data.label }
        </Text>

        <Spacer />

        {
          props.data.sublabel &&
            <Text
              fontSize={14}
              lineHeight="24px"
              as="i"
              maxWidth="100%"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              { props.data.sublabel }
            </Text>
        }
      </HStack>
    </components.Option>
  );
}

function ValueContainer({ children, ...props }: ValueContainerProps & { selectProps: Props & { icon: IconKey } }) {
  return (
    <components.ValueContainer {...props}>
      <Icon color="gray.500" icon={props.selectProps.icon} size={4} style={{ position: 'absolute', left: 12 }} />
      { children }
    </components.ValueContainer>
  );
}

export function SearchSelect<T>(props: Props<{ label: string, value: T, sublabel?: string, isDisabled?: boolean, img?: string }, false>) {
  return <Select styles={customStyles} components={{ Option }} {...props} />;
}

export function SearchIconSelect<T>(props: Props<{ label: string, value: T, img?: string }, false, GroupBase<{ label: string, value: T, img?: string }>> & { icon: IconKey }) {
  return <Select styles={searchIconStyles} components={{ Option, ValueContainer, IndicatorsContainer: () => null }} {...props} />;
}

export function MultiSelect<T>(props: Props<{ label: string, value: T }>) {
  return <Select isMulti styles={multiCustomStyles} {...props} />;
}

export function MultiCreatableSelect<T>(props: Props<{ label: string, value: T }>) {
  return <CreatableSelect isMulti styles={multiCustomStyles} {...props} />;
}